import {
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getSeconds,
  getYear,
  isAfter,
  isBefore,
  isEqual
} from "date-fns";

export const getParam = (queryParams, hashParam, param, session) => {
  if (param in queryParams) {
    return queryParams[param];
  } else if (param in hashParam || `?${param}` in hashParam) {
    return `?${param}` in hashParam
      ? hashParam[`?${param}`]
      : hashParam[param];
  } else if(session) {
    return session[param];
  } else {
    return null;
  }
};

export const renameProp = (oldProp, newProp, { [oldProp]: old, ...others }) => ({
  [newProp]: old,
  ...others
});

/**
 * This function remove the duplicated objects from an array of objects.
 * @param {Object[]} objects - The array of objects to filter
 * @returns {Object[]} - The array with unique objects
 */
export const removeDuplicatedObjects = (objects) => {
  let objectsSerialized = objects.map(e => JSON.stringify(e));
  return [...new Set(objectsSerialized)].map(e => JSON.parse(e));
}

/**
 * This function returnsthe absolute date that is to say without the hours or minutes (by default set to midnight).
 * First, this function convert the date in the locale timezone of the device and then use the year, month and date passed.
 * @param {Date} date - The date to convert
 * @returns {Date} - The new date set to midnight
 */
export const getAbsoluteDate = (date) => {
  return new Date(getYear(date), getMonth(date), getDate(date));
};

/**
 * This function returnsthe result of the comparison between two dates.
 * First, this function convert the dates in the locale timezone of the device and then compare both.
 * @param {Date} dateA - The first date to compare
 * @param {Date} dateB - The second date to compare
 * @param {String} comparison - The comparison operator to use to compare dateA and dateB (<, <=, >, >=, ==, !=)
 * @returns {Boolean} - The result of the comparison
 */
export const compareDate = (dateA, dateB, comparison) => {
  switch (comparison) {
    case "<": return isBefore(dateA, dateB);
    case ">": return isAfter(dateA, dateB);
    case "<=": return isBefore(dateA, dateB) || isEqual(dateA, dateB);
    case ">=": return isAfter(dateA, dateB) || isEqual(dateA, dateB);
    case "==": return isEqual(dateA, dateB);
    case "!=": return !isEqual(dateA, dateB);
    default: return false;
  }
};

/**
 * This function returnsthe result of the comparison between two absolute dates (we don't take care about hours and minutes).
 * First, this function convert the dates in the locale timezone of the device and then use the year, month and date.
 * @param {Date} dateA - The first date to compare
 * @param {Date} dateB - The second date to compare
 * @param {String} comparison - The comparison operator to use to compare dateA and dateB (<, <=, >, >=, ==, !=)
 * @returns {Boolean} - The result of the comparison
 */
export const compareAbsoluteDate = (dateA, dateB, comparison) => {
  const a = new Date(getYear(dateA), getMonth(dateA), getDate(dateA));
  const b = new Date(getYear(dateB), getMonth(dateB), getDate(dateB));
  return compareDate(a, b, comparison);
};

/**
 * This function returnsthe result of the comparison between two times.
 * First, this function convert the dates in the locale timezone of the device and then use the hour, minute and second.
 * @param {Date} dateA - The first date with the time to compare
 * @param {Date} dateB - The second date with to compare
 * @param {String} comparison - The comparison operator to use to compare timeA and timeB (<, <=, >, >=, ==, !=)
 * @returns {Boolean} - The result of the comparison
 */
export const compareAbsoluteTime = (dateA, dateB, comparison) => {
  const a = new Date(2020, 1, 1, getHours(dateA), getMinutes(dateA), getSeconds(dateA));
  const b = new Date(2020, 1, 1, getHours(dateB), getMinutes(dateB), getSeconds(dateB));
  return compareDate(a, b, comparison);
};

/**
 * This function returnsthe result of the comparison between two times.
 * First, this function convert the dates in UTC date and then use the hour, minute and second.
 * @param {Date} dateA - The first date with the time to compare
 * @param {Date} dateB - The second date with to compare
 * @param {String} comparison - The comparison operator to use to compare timeA and timeB (<, <=, >, >=, ==, !=)
 * @returns {Boolean} - The result of the comparison
 */
export const compareAbsoluteUTCTime = (dateA, dateB, comparison) => {
  const a = new Date(2020, 1, 1, dateA.getUTCHours(), dateA.getUTCMinutes(), dateA.getUTCSeconds());
  const b = new Date(2020, 1, 1, dateB.getUTCHours(), dateB.getUTCMinutes(), dateB.getUTCSeconds());
  return compareDate(a, b, comparison);
};
