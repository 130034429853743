import * as actions from "gadget_v2/actions/session";

export const redirectToIdefix = (dispatch) => (httpStatus, sessions) => {
  const { organizationId, ...session } = sessions;
  const origin = session[organizationId].origin;
  if ((httpStatus === 401 || httpStatus === 403) && origin) {
    if (dispatch) { 
      dispatch(actions.clear()) 
    } else {
      window.location.href = origin;
    }
  }
};