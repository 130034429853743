export function computeCartTotals(cart, productsIndex) {
  return Object.keys(cart.products).reduce(
    (accumulator, productKey) => {
      const product = productsIndex[productKey];
      if (!product || product.sold_out) {
        return accumulator;
      }

      const quantity = cart.products[productKey].quantity;
      accumulator.articles += quantity;
      accumulator.amount += product.price.amount * quantity;
      accumulator.nbDecimal = product.price.currency_nb_decimal;
      accumulator.symbol = product.price.currency_symbol;
      return accumulator;
    },
    { articles: 0, amount: 0, nbDecimal: 0, symbol: '' }
  );
}

export function applyPendingUpdateToCartProducts(products, pendingUpdate) {
  let productsToReturn = { ...products };
  pendingUpdate.forEach((update) => {
    let key = [[update.data.web_location, update.data.item]];
    if (update.data.row_id) {
      key = [[update.data.web_location, update.data.item, update.data.row_id]];
    }

    const currentQantity = productsToReturn[key] !== undefined
      ? productsToReturn[key].quantity
      : 0;
    const currentMenu = productsToReturn[key] !== undefined
      ? productsToReturn[key].menu
      : [];
    const currentRowId = productsToReturn[key] !== undefined
      ? productsToReturn[key].row_id
      : null;


    switch (update.action) {
      case "incrementProduct":
        productsToReturn = {
          ...productsToReturn,
          [key]: {
            id: update.data.item,
            web_location: update.data.web_location,
            quantity: currentQantity + update.data.delta,
            menu: currentMenu,
            row_id: currentRowId,
          }
        };
        break;
      case "decrementProduct":
        productsToReturn = {
          ...productsToReturn,
          [key]: {
            id: update.data.item,
            web_location: update.data.web_location,
            quantity: currentQantity - update.data.delta,
            menu: currentMenu,
            row_id: currentRowId,
          }
        };
        if ((currentQantity - update.data.delta) === 0) {
          delete productsToReturn[key];
        }
        break;
      case "addWithdrawalInformation":
        let productsToReturnKeys = Object.keys(productsToReturn);
        productsToReturnKeys.forEach(key2 => {
          if (productsToReturn[key2].web_location === update.data.web_location) {
            productsToReturn[key2] = { ...productsToReturn[key2], ...update.data };
          }
        });
        break;
      case "updateProductMenu":
        const newProduct = {
          id: update.data.item,
          web_location: update.data.web_location,
          menu: update.data.menu,
          quantity: update.data.quantity,
        };
        if (update.data.row_id) {
          newProduct.row_id = update.data.row_id;
        }
        productsToReturn = {
          ...productsToReturn,
          [key]: newProduct,
        };
        break;
      default:
        break;
    }
  });
  return productsToReturn;
}
