export const WALLET_FETCH_SUCCESS = "WALLET_FETCH_SUCCESS";
export const WALLET_FETCH_ERROR = "WALLET_FETCH_ERROR";

export const fetchWalletSuccess = (data) => ({
  type: WALLET_FETCH_SUCCESS,
  data
});

export const fetchWalletError = (res) => ({
  type: WALLET_FETCH_ERROR,
  error: res.response,
  status: res.status
});