export default `
back-to-cashless-account = Takaisin cashless tililleni
day-monday = Maanantai
day-tuesday = Tiistai
day-wednesday = Keskiviikko
day-thursday = Torstai
day-friday = Perjanta
day-saturday = Lauantai
day-sunday = Sunnuntai
not-enough-money-title = Saldo ei riitä
validate = Vahvista
welcome-to-orga = { $organizationId } - tervetuloa
empty-cart-message = Ostoskorisi on tyhjä
view-less = Näytä vähemmän
view-more = Näytä enemmän
view-order = Näytä tilaus
error-unknown = Tunnistamaton virhe
error-500-title = Oho... jotain meni pieleen!
error-500-description = Tapahtui virhe, yritä myöhemmin uudelleen
error-404-title = 404
error-404-description = Sivua ei löydy
error-preorder-disabled = Ennakkotilaus ei ole tällä hetkellä saatavilla
error-cart-item-sold-out = Valitettavasti tämä tuote on loppuunmyyty
sold_out = Loppuunmyyty
no-cb-title = Määrittämätön luottokortti
preorder-title = Online ennakkotilaus
order-history = Tilaushistoria
order-history-description = Tarkista, Nouda
order-history-action = { $orderNumber ->
 [one] {$orderNumber} tilaus
 *[other] {$orderNumber} tilaukset
}
order-history-message-to-retrieve = Ei lunastettavia tai noudettavia tilauksia
order-history-message-retrieved = Et ole lunastanut tai noutanut yhtään tilausta
personal-infos-title = Henkilökohtaiset tiedot
personal-infos-description = Säästä aikaa
personal-infos-action-no-cb = Ei tallettuja korttitietoja
personal-infos-action-cb = Korttitiedot tallennettu
preorder-description = Tilaa, nauti
preorder-description-disabled = Saatavilla pian
preorder-action-disabled-no-period = Ennakkotilaus ei ole saatavilla enää
preorder-action-disabled-period = Ennakkotilaus alkaa { DATETIME($period, day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric") }, nähdään pian!
preorder-action = { $productNumber ->
 [one] { $productNumber } tuote tilattavissa
 *[other] { $productNumber } tuotteet tilattavissa
}
period-config-datetime-from- = Välillä { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } - { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-datetime-from-until = { DATETIME($start, day: "numeric", month: "numeric", year: "numeric") }, { DATETIME($start, hour: "numeric", minute: "numeric") } - { DATETIME($end, hour: "numeric", minute: "numeric") }
period-config-datetime-from = Alkaen { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-datetime-until = Päättyen { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-date-interval = Alkaen DATETIME($startDate, day: "numeric", month: "numeric", year: "numeric") päättyen DATETIME($endDate, day: "numeric", month: "numeric", year: "numeric")
your-profile = Käyttäjäprofiili
name = Sukunimi
first-name = Etunimi
email = Sähköposti
your-payment-method = Maksutapa
save-card = Tallenna korttitiedot
modify = Muokkaa
cart-total = Yhteensä: { $articleNumber ->
 [one] { $articleNumber } tuote
 *[other] { $articleNumber } tuotteet
} { $price }
cart-total-partial = Yhteensä: { $articleNumber ->
 [one] { $articleNumber } tuote
 *[other] { $articleNumber } tuotteet
}
cart = Ostoskori
order-history-header-title = Kiitos tilauksestasi!
pickup-order = Lunasta tilaukseni
history-page-header = Tilaushistoria
orders-to-pickup = Tilauksia lunastettavissa tai noudettavissa ({ $quantity })
fulfilled-orders = Lunastetut tilaukset ({ $quantity })
preparing-orders = Valmisteltavat tilaukset ({ $quantity })
to-prepare-orders = Valmistelua odottavat tilaukset ({ $quantity })
other-orders = Muut tilaukset ({ $quantity })
possible-withdrawal-dates = Noutoajat :
selected-withdrawal-date = Valitse noutoaika :
selected-withdrawal-time-different-day = Välillä { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } - { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-same-day = { DATETIME($start, day: "numeric", month: "numeric", year: "numeric") } välillä { DATETIME($start, hour: "numeric", minute: "numeric") } - { DATETIME($end, hour: "numeric", minute: "numeric") }
selected-withdrawal-time-start = Alkaen { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-end = Päättyen { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
order-summary-ref = Tilaus { $orderWithdrawalRef }
order-summary-title = Tilauksen yhteenveto
order-summary-info = QR-koodi on saatavilla Tilaushistoriassa
order-summary-info-anonymous = Tilauksen yhteenveto on lähetetty sähköpostiisi
order-created-date = { DATETIME($createdDate, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
order-title = Tilaus { $orderWithdrawalRef }
ask-invoice = Pyydä kuittia
create-invoice = Kuitin luonti
download-invoice = Lataa kuitti
invoice-hint = Täytä tiedot jotka haluat tulostuvan kuitillesi
invoice-hint-edit = Varoitus: kun kuitti on luotu, sitä ei voi enää muokata
company = Yritys
vat-number-invoice = Y-tunnus
address = Osoite
postal-code = Postinumero
city = Postitoimipaikka
country = Maa
next = Seuraava
order = Tilaa
order-follow-up-cta = Seuraa tilausta
order-header-title = Kiitos tilauksestasi!
order-header-already-withdrawn = Tämä tilaus on jo lunastettu.
order-header-cancelled = Tämä tilaus on peruttu.
order-cancelled = Tilaus peruttu.
order-header-initiated = Tätä tilausta ei ole vahvistettu.
order-initiated = Tilausta ei ole vahvistettu.
order-header-not-fulfilled = Tätä tilausta ei ole lunastettu oikein.
order-not-fulfilled =
order-header-preparing = Tämä tilaus on käsitelty.
order-header-to-prepare = Tämä tilaus odottaa käsittelyä.
order-to-prepare = Tilauksesi odottaa käsittelyä.
 Kun tilauksesi on vastaanotettu, menee { $preparationTime ->
 [one] {$preparationTime} minuutti
 *[other] {$preparationTime} minuuttia
} sen valmistamiseen.
order-preparing = Tilauksesi on käsitelty.
 Arvioitu valmisteluaika : { $preparationTime } min.
 Arvioitu valmistumisaika : { DATETIME($estimationReadyTime, hour: "numeric", minute: "numeric") }.
order-status-canceled = Peruttu
order-status-delivered = Toimitettu
order-status-initiated = Odottaa
order-status-not-fulfilled = Vanhentunut
order-status-preparing = Valmistumassa
order-status-ready = Valmis
order-status-to-deliver = Toimitettavissa
order-status-to-pay = Maksettavissa
order-status-to-prepare = Valmisteltavissa
order-status-withdrawn = Lunasta
order-pick-up-mode-delivery = Tilauksesi on toimituksessa.
pay = Maksa
payment-method-title = Maksutapa
create-order = Tilaa nyt
cart-checkout-header = Maksutapa
cart-checkout-title = Täytä korttitietosi
cart-checkout-verification = Maksun vahvistaminen
cart-checkout-error = Virhe.
cart-checkout-error-retry-with-same-card = Yritä uudelleen samalla kortilla.
cart-checkout-error-retry-with-another-card = Yritä uudelleen toisella kortilla.
cart-checkout-error-invalid-card-number = Tarkista korttinumerosi.
cart-checkout-cta = Maksa { $price }
cart-validation-step-title = Tiedot
cart-validation-step-checkbox = Olen lukenut yllä olevat tiedot.
cgv-step-accept = Hyväksyn
cgv-step-cgv = Käyttöehdot
cart-topup-title = Maksa tilaukseni
cart-topup-info = Lisälataus: voit ladata enemmän saldoa kuin puuttuva summa maksaaksesi tilauksesi.
cart-topup-info-sup = Huom : pienin lataussumma on { $minAmount }.
cart-topup-cta = Vahvista
cart-topup-payment-title = Syötä tietosi
cart-topup-payment-info = Tarkista saldosi maksaaksesi tilauksesi.
cart-topup-payment-cta = Vahvista
card-enrollment-title = Täytä korttitietosi
card-enrollment-cta = Tallenna
close = Sulje
remove-card = Korttisi { $masked } korvataan uudella kortillasi.
yes = Kyllä
cancel = Peruuta
logout = Kirjaudu ulos
home = Koti
home-idefix-title = Cashless tili
home-idefix-description = Saldo ja maksutapahtumat
home-idefix-action = Cashless tilillesi
today = Tänään
input-error-email-invalid = Syötä kelvollinen sähköpostiosoite.
input-error-phone-invalid = Syötä kelvollinen puhelinnumero.
input-error-number-invalid = Syötä kelvollinen numero.
input-error-number-too-small = Pienin arvo on { $minValue }.
input-error-number-too-big = Suurin arvo on { $maxValue }.
input-error-date-impossible = Kyseistä päivää ei voi valita.
input-error-date-invalid = Päivämäärä on virheellinen.
input-error-date-invalid-format = Päivämäärän muoto on virheellinen, pitäisi olla { $dateFormat }.
input-error-date-not-configured = Päivämäärää ei voi valita.
input-error-date-to-early = Päivämäärän pitää olla { $minDate } jälkeen.
input-error-date-to-late = Päivämäärän pitää olla { $maxDate } ennen.
input-error-input-too-short = Tämän kentän pitää olla vähintään { $minLength } merkkiä.
input-error-input-too-long = Tämän kentän pituus on enintään { $maxLength } merkkiä.
input-error-input-empty = Tämä kenttä ei voi olla tyhjä.
input-error-input-invalid = Tämä kenttä on virheellinen.
input-error-input-required = Tämä kenttä on pakollinen.
input-error-input-not-checked = Kentät eroavat toisistaan.
form-submit-button = Lähetä
form-reset-button = Tyhjennä
ok = OK
required-informations = Tarvittavat tiedot
withdrawal-date = Lunastus päiväys
withdrawal-date-select = Valitse päivä
withdrawal-interval = - alkaen { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") },  { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } asti.
withdrawal-location = { $count ->
 [one] Noutopaikka
 *[other] Noutopaikka
}
withdrawal-location-not-available = Noutopaikka ei saatavilla
withdrawal-location-time-possible = Mahdolliset noutopaita ja ajat
withdrawal-location-value = Noutopaikka :
withdrawal-order = Tilauksen nouto
withdrawal-order-several-shop-information = Olet ostamassa tuotteita useammasta myyntipisteestä. Tilauksesi jaetaan { $shopCount } osaan.
 Valitse noutoaika jokaiselle noudolle, mikäli pakollinen.
withdrawal-products = { $productCount ->
 [one] Tuotteen
 *[other] Tuotteiden
}
withdrawal-products-pick-up = { $productCount ->
 [one] Tuotteen nouto
 *[other] Tuotteiden nouto
}
withdrawal-time = Noutoaika
withdrawal-time-cta-expand = Katso aukioloajat
withdrawal-timeslot = Noutoaika
withdrawal-timeslot-select = Valitse aikaväli
withdrawal-timeslot-select-free = Vapaa aikaväli
withdrawal-time-possible = Mahdollinen noutoaika
withdrawn-date = Noutopäivämäärä:
withdrawn-date-formatted = { DATETIME($date, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
withdrawn-location = Noutopiste :
cart-expired-title = Ostoskorisi on vanhentunut
cart-expired-description = Ostoskorisi on vanhentunut ja tuotteet on poistettu. Lisää uudet tuotteet ostoskoriisi.
product-cta-choose-menu = Valitse
menu-cta-add-to-cart = Lisää ostoskoriin
menu-cta-modify = Muokkaa
menu-cta-modify-validate = Vahvista muutokset
menu-error-exact-product-quantity = Sinun pitää valita { $quantity ->
  [one] {$quantity} tuote
  *[other] {$quantity} tuotetta
} tästä kategoriasta.
menu-error-min-product-quantity = Sinun pitää valita vähintään { $minQuantity ->
  [one] {$minQuantity} tuote
  *[other] {$minQuantity} tuotetta
} tästä kategoriasta.
menu-see-details-cta = Katso lisätiedot
total = Yhteensä
cart-cta = Näytä ostoskorini
or = tai
preorder-not-available = Ennakkotilaus ei ole tällä hetkellä käytettävissä.
`;
