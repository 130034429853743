import { combineEpics } from "redux-observable";

import { errorRequest, redirect } from "./auth";
import { updateCartEpic, getInitialCartEpic, beforeUpdateCartEpic, afterUpdateCartEpic } from "./cart";
import { fetchLocation, initFetchLocation } from "./locations";
import { fetchUser } from "./user";
import { fetchWallet } from "./wallet";
import { fetchWebStore } from "./webstore";

const rootEpic = combineEpics(
  errorRequest,
  redirect,
  updateCartEpic,
  beforeUpdateCartEpic,
  afterUpdateCartEpic,
  getInitialCartEpic,
  fetchWebStore,
  initFetchLocation,
  fetchLocation,
  fetchUser,
  fetchWallet
);

export default rootEpic;