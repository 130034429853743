import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, mergeMap, filter } from "rxjs/operators";

import * as actions from "gadget_v2/actions/webstore";
import { initLocations } from "gadget_v2/actions/locations";
import { updateHeader } from "gadget_v2/actions/header";
import configure from "gadget_v2/helpers/fetcher";
import { SET_SESSION } from "gadget_v2/actions/session";
import { WALLET_FETCH_SUCCESS } from "gadget_v2/actions/wallet";

export const fetchWebStore = (action$, state$) => action$.pipe(
  filter(({ type }) => (type === SET_SESSION && !state$.value.session.isAuthenticated) || type === WALLET_FETCH_SUCCESS),
  filter(() => state$.value.webStore.loading || !state$.value.webStore.data),
  mergeMap(() => {
    const { organizationId, language, storeUUID } = state$.value.session;
    const session = state$.value.session[organizationId];
    return ajax(configure(session, language.current)({ resource: `/organizations/${organizationId}/clientwebstores/${storeUUID}` })).pipe(
      mergeMap((res) => {
        if (res.status === 200) {
          const locations = res.response.web_locations.reduce((acc, webLocation) => ({
            ...acc,
            [webLocation.id]: {
              id: webLocation.id,
              resource: webLocation.href,
            }
          }), {});
          const displayHeader = res.response.display_header
          return [actions.fetchWebStoreSuccess(res.response), initLocations(locations), updateHeader(undefined, undefined, displayHeader)];
        }
        return actions.fetchWebStoreError(res);
      }),
      catchError((res) => of(actions.fetchWebStoreError(res)))
    )
  })
);