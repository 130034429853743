import * as actions from "gadget_v2/actions/user";

const initialState = {
  loading: true,
  data: null,
  error: null
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case actions.USER_FETCH:
      return initialState;
    case actions.USER_FETCH_SUCCESS:
      return { ...state, loading: false, data: action.data };
    case actions.USER_FETCH_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export default user;