import { createStore, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { createTransform, persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootEpic from "./gadget_v2/epics";
import reducers from "./gadget_v2/reducers";

const rehydration = createTransform(
  (inboundState) => inboundState,
  (outboundState, key) => {
    const state = { ...outboundState, ready: false };
    if (key === "session") {
      return { ...state, language: { accepted: [], current: null } };
    }
    return state;
  },
  { whitelist: ["session", "cart"] },
);

const persistConfig = {
  key: "root",
  whitelist: ["session", "cart"],
  storage,
  transforms: [rehydration],
};

const configureStore = (dependencies) => {
  const epicMiddleware = createEpicMiddleware(dependencies);
  const persistedReducer = persistReducer(persistConfig, reducers);
  const store = createStore(persistedReducer, applyMiddleware(epicMiddleware));
  const persistor = persistStore(store);
  epicMiddleware.run(rootEpic);
  return { persistor, store };
};

export default configureStore;
