import * as constants from "styles/constants";

/**
 * This object provides all the default values to use to compute the theme.
 * These values can be overridden by the organizer.
 */
const themeDefaultConstants = {
  /** The widget breakpoint sizes */
  SIZE_WIDGET_WIDTH_SMALL: 450,
  SIZE_WIDGET_WIDTH_MEDIUM: 650,
  SIZE_WIDGET_WIDTH_LARGE: 960,
  /** The fonts */
  FONTS: [],
  FONT_FAMILY: "Roboto,Helvetica,Arial,sans-serif",
  /** The offset / coefficient values to compute primary color variants */
  OFFSET_COLOR_VARIANT_HOVER: 0.25, // To apply to darken MUI function
  OFFSET_COLOR_VARIANT_DISABLED: 0.60, // To apply to lighten MUI function
  OFFSET_COLOR_VARIANT_BACKGROUND: 0.96, // To apply to lighten MUI function
  OFFSET_COLOR_VARIANT_SHADOW: 0.85, // To apply to lighten MUI function
  OFFSET_COLOR_VARIANT_BORDER: 0.05, // To apply to darken MUI function
  /** The main colors to incorporate in the MUI palette */
  COLOR_PRIMARY: constants.COLOR_BLUE_WEEZTICKET,
  COLOR_LINK: null, // Use the primary color by default
  COLOR_BUTTON_LINK_HOVER: null, // Use the primary color by default
  COLOR_ERROR: "#f44336",
  COLOR_WARNING: "#faa43e",
  COLOR_SUCCESS: "#66bb6a",
  COLOR_TEXT_PRIMARY_LIGHT: "#000000de",
  COLOR_TEXT_PRIMARY_DARK: "#ffffffff",
  COLOR_TEXT_SECONDARY_LIGHT: "#00000099",
  COLOR_TEXT_SECONDARY_DARK: "#ffffff99",
  COLOR_TEXT_WITH_NO_BACKGROUND: null, // Use the default primary text color instead
  COLOR_GREY_1_LIGHT: "#ededed",
  COLOR_GREY_1_DARK: "#46484c",
  COLOR_GREY_2_LIGHT: "#d4d4d4",
  COLOR_GREY_2_DARK: "#303030",
  COLOR_GREY_3_LIGHT: "#bdbdbd",
  COLOR_GREY_3_DARK: "#464646",
  /** The dark mode */
  ALLOW_DARK_MODE: false,
  /** The main customized colors */
  COLOR_CARD_BORDER: null, // Use the default color
  COLOR_HISTORY_POSITIVE_AMOUNTS: null, // Use the default computed color
  COLOR_HISTORY_NEGATIVE_AMOUNTS: null, // Use the default computed color
  /** The main customized background colors */
  COLOR_BACKGROUND_WIDGET: null,
  COLOR_BACKGROUND_CARD: null, // Use the default computed color
  COLOR_BACKGROUND_CARD_LOADING_1: null, // Use the default computed color
  COLOR_BACKGROUND_CARD_LOADING_2: null, // Use the default computed color
  COLOR_BACKGROUND_CARD_WALLET: null, // Use the default computed color
  COLOR_BACKGROUND_CARD_WALLET_BALANCE: null, // Use the default color
  COLOR_BACKGROUND_CARD_WALLET_BALANCE_DETAIL: null, // Use the default color
  COLOR_BACKGROUND_CARD_WALLET_TICKET_CHIP_DETAIL: null, // Use the default color
  COLOR_BUTTON_BACKGROUND_OUTLINED: "#ffffff",
  COLOR_BUTTON_BACKGROUND_CONTAINED_PRIMARY_HOVER: null, // Use the primary variant computed color by default
  COLOR_SPLIT_PAYMENT_WARNING: "#ffcc33", // displayable in the history transaction entry when split payment is used
  DISPLAY_COLOR_BACKGROUND_HISTORY_CARD_TRANSPARENT: true,
  DISPLAY_COLOR_BACKGROUND_HISTORY_POSITIVE_AMOUNTS: true,
  DISPLAY_COLOR_BACKGROUND_HISTORY_NEGATIVE_AMOUNTS: false,
};

export default themeDefaultConstants;
