import React, { createContext } from "react";

const initialContext = () => {
  throw new Error("Trying to use ResourceContext with no provider.");
};

const ResourceContext = createContext(initialContext);
export default ResourceContext;

export const ResourceProvider = ({ appKey, url, children }) => (
  <ResourceContext.Provider value={{ appKey, baseUrl: url }}>
    {children}
  </ResourceContext.Provider>
);
