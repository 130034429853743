import { ofType } from "redux-observable";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, map, mergeMap } from "rxjs/operators";

import * as actions from "gadget_v2/actions/locations";
import configure from "gadget_v2/helpers/fetcher";

export const initFetchLocation = (action$) => action$.pipe(
  ofType(actions.LOCATIONS_INIT),
  mergeMap(action => Object.values(action.webLocations).map(location => actions.fetchLocation(location))),
);

export const fetchLocation = (action$, state$) => action$.pipe(
  ofType(actions.LOCATION_FETCH),
  mergeMap(({ webLocation }) => {
    const { organizationId, language } = state$.value.session;
    const session = state$.value.session[organizationId];
    const wallet = state$.value.wallet.data;
    return ajax(configure(session, language.current)({ resource: webLocation.resource })).pipe(
      map((res) => {
        if (res.status === 200) return actions.fetchLocationSuccess(webLocation, res.response, wallet);
        return actions.fetchLocationError(webLocation, res);
      }),
      catchError((res) => of(actions.fetchLocationError(webLocation, res)))
    )
  })
);
