import { ofType } from "redux-observable";
import { ignoreElements, map, mergeMap } from "rxjs/operators";

import * as cart from "gadget_v2/actions/cart";
import * as locations from "gadget_v2/actions/locations";
import * as sessions from "gadget_v2/actions/session";
import * as user from "gadget_v2/actions/user";
import * as webstore from "gadget_v2/actions/webstore";
import { redirectToIdefix } from "gadget_v2/helpers/redirection";

export const errorRequest = (action$, state$) => action$.pipe(
  ofType(cart.SYNCHRONIZE_CART_ERROR, locations.LOCATION_FETCH_ERROR, user.USER_FETCH_ERROR, webstore.WEBSTORE_FETCH_ERROR),
  map((action) => {
    if (action.status === 401 || action.status === 403) {
      return sessions.clear(action.status, state$.value.session)
    }
    return { type: '' };
  }),
);

export const redirect = (action$, state$) => action$.pipe(
  ofType(sessions.CLEAR_SESSION),
  map((action) => redirectToIdefix(null)(action.status, action.session)),
  ignoreElements()
);