export const WEBSTORE_FETCH_SUCCESS = "WEBSTORE_FETCH_SUCCESS";
export const WEBSTORE_FETCH_ERROR = "WEBSTORE_FETCH_ERROR";

export const fetchWebStoreSuccess = (data) => ({
  type: WEBSTORE_FETCH_SUCCESS,
  data
});

export const fetchWebStoreError = (res) => ({
  type: WEBSTORE_FETCH_ERROR,
  error: res.response,
  status: res.status
});