import produce from "immer";

import { USER_FETCH_SUCCESS } from "gadget_v2/actions/user";
import { WALLET_FETCH_ERROR, WALLET_FETCH_SUCCESS } from "gadget_v2/actions/wallet";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const init = produce((draft) => {
  draft.loading = true;
});

const success = produce((draft, { data }) => {
  draft.loading = false;
  draft.data = data;
  draft.error = null;
});

const error = produce((draft, { error }) => {
  draft.loading = false;
  draft.data = null;
  draft.error = error;
});

const wallet = (state = initialState, action) => {
  switch (action.type) {
    case USER_FETCH_SUCCESS:
      return init(state, action);
    case WALLET_FETCH_SUCCESS:
      return success(state, action);
    case WALLET_FETCH_ERROR:
      return error(state, action);
    default:
      return state;
  }
};

export default wallet;