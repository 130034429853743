import queryString from "query-string";

import { headers } from "libs/api/interceptors";

/**
 * Nemo pay constant, used in headers for useing new API
 */
const NEMO_PAY_2023 = "2023-01-01";

/**
 * Preorders API
 * @param client - API client
 * @param config - API configuration
 */
export default function Preorders(client, config) {
  const { host, organizationId, widgetUUID } = config;

  const removeRowId = (products) => products.map(((v) => ({
    ...v,
    id: v.menu_rows.length > 0 ? v.id : null,
  })));

  const getEndpoint = (url) => `${host}pay/public/organizations/${organizationId}${url}`;

  const getInterceptors = (url, nemopayVersion = null) => (
    [
      ((v) => ({ ...v, endpoint: getEndpoint(url) })),
      headers({ "Nemopay-Version": nemopayVersion || undefined }),
    ]
  );

  return {
    getShops: () => client.request({
      method: "GET",
      interceptors: getInterceptors(`/widgets/${widgetUUID}/shops`, NEMO_PAY_2023),
    }),
    getClientInfo: () => client.request({
      method: "GET",
      interceptors: getInterceptors("/client"),
    }),
    getShopCart: (id) => client.request({
      method: "GET",
      interceptors: getInterceptors(`/widgets/${widgetUUID}/carts/${id}`, NEMO_PAY_2023),
    }),
    createShopCart: (data = { rows: [] }) => client.request({
      method: "POST",
      interceptors: getInterceptors(`/widgets/${widgetUUID}/carts`, NEMO_PAY_2023),
      body: { ...data, rows: removeRowId(data.rows) },
    }),
    patchShopCart: (id, data) => client.request({
      method: "PATCH",
      interceptors: getInterceptors(`/widgets/${widgetUUID}/carts/${id}`, NEMO_PAY_2023),
      body: { ...data, rows: data.rows ? removeRowId(data.rows) : undefined },
    }),
    launchAction: ({ data, href, method }) => client.request({
      method: method.toUpperCase(),
      interceptors: [((v) => ({
        ...v,
        endpoint: `${host.slice(0, host.length - 1)}${href}`,
      })),
      headers({ "Nemopay-Version": NEMO_PAY_2023 }),
      ],
      body: data,
    }),
    getOrders: ({ cartId, walletId }) => client.request({
      method: "GET",
      interceptors: getInterceptors(
        `/widgets/${widgetUUID}/orders?${queryString.stringify(
          { cart_uuid: cartId, wallet_id: walletId },
        )}`, NEMO_PAY_2023,
      ),
    }),
    getOrder: (id) => client.request({
      method: "GET",
      interceptors: getInterceptors(`/widgets/${widgetUUID}/orders/${id}`, NEMO_PAY_2023),
    }),
    getPayment: ({ cardId, body }) => client.request({
      method: "POST",
      interceptors: getInterceptors(`/widgets/${widgetUUID}/carts/${cardId}/pay`, NEMO_PAY_2023),
      body,
    }),
  };
}
