export const USER_FETCH = "USER_FETCH";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_ERROR = "USER_FETCH_ERROR";

export const fetchUser = () => ({
  type: USER_FETCH,
});

export const fetchUserSuccess = (data) => ({
  type: USER_FETCH_SUCCESS,
  data
});

export const fetchUserError = (res) => ({
  type: USER_FETCH_ERROR,
  error: res.response,
  status: res.status
});