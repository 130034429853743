import {
  add,
  eachDayOfInterval,
  getDate,
  getHours,
  getISODay,
  getMinutes,
  getMonth,
  getYear,
  isEqual,
} from "date-fns";
import {
  utcToZonedTime, zonedTimeToUtc
} from "date-fns-tz";
import {
  compareAbsoluteDate,
  compareAbsoluteUTCTime,
  compareDate,
} from "./utils";

export const countProductsInWeblocation = (productsIndex, weblocationId, ignoreSoldout = false) => {
  return Object.keys(productsIndex)
    .filter((key) => (
      key.split(',')[0] === weblocationId.toString()
        ? productsIndex[key].variants
        // variants of a product is considered as a simple product and, consequently the parent product is not counted
        ? productsIndex[key].variants.length === 0 && (productsIndex[key].sold_out === false || ignoreSoldout)
        : productsIndex[key].sold_out === false || ignoreSoldout
        : false))
    .length || 0;
};

export const periodConfigToTimeSlots = (periodConfig, unexpiredOnly) => {
  const now = new Date(Date.now());
  const nowUTC = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes()));
  const orgaTz = periodConfig.timezone;
  const orgaNow = utcToZonedTime(nowUTC, orgaTz);

  // We just return an elementary timeslot according to the periodConfig start and end fields
  if (periodConfig.start && periodConfig.end) {
    if (!unexpiredOnly || Date.parse(periodConfig.end) >= now.getTime()) {
      return [
        {
          start: new Date(Date.parse(periodConfig.start)),
          end: new Date(Date.parse(periodConfig.end))
        }
      ];
    } else {
      return [];
    }
  }

  // We generate a timeslot according to the periodConfig
  if (periodConfig.start_time && periodConfig.end_time && periodConfig.start_date && periodConfig.end_date) {
    const periodConfigStartDate = new Date(Date.parse(periodConfig.start_date));
    const periodConfigStartTimeSplit = periodConfig.start_time.split(":");
    let periodConfigStartTime = new Date(2020, 1, 1, 0, 0, 0);
    periodConfigStartTime.setUTCHours(periodConfigStartTimeSplit[0]);
    periodConfigStartTime.setUTCMinutes(periodConfigStartTimeSplit[1]);

    const periodConfigEndDate = new Date(Date.parse(periodConfig.end_date));
    const periodConfigEndTimeSplit = periodConfig.end_time.split(":");
    let periodConfigEndTime = new Date(2020, 1, 1, 0, 0, 0);
    periodConfigEndTime.setUTCHours(periodConfigEndTimeSplit[0]);
    periodConfigEndTime.setUTCMinutes(periodConfigEndTimeSplit[1]);

    const periodConfigExcludedDays = periodConfig.excluded_days && periodConfig.excluded_days.length && periodConfig.excluded_days.map((day) => new Date(Date.parse(day)));

    const timeSlotOverTwoDays = compareAbsoluteUTCTime(periodConfigStartTime, periodConfigEndTime, ">=");

    const startDate = unexpiredOnly && compareAbsoluteDate(periodConfigStartDate, orgaNow, "<=")
      ? timeSlotOverTwoDays
        ? compareAbsoluteUTCTime(orgaNow, periodConfigEndTime, "<")
          ? add(new Date(Date.parse(orgaNow.toISOString().split("T")[0])), { days: -1 })
          : new Date(Date.parse(orgaNow.toISOString().split("T")[0]))
        : compareAbsoluteUTCTime(orgaNow, periodConfigEndTime, "<")
          ? new Date(Date.parse(orgaNow.toISOString().split("T")[0]))
          : add(new Date(Date.parse(orgaNow.toISOString().split("T")[0])), { days: 1 })
      : periodConfigStartDate;
    const endDate = periodConfigEndDate;

    if (compareAbsoluteDate(startDate, endDate, "<=")) {
      return eachDayOfInterval({ start: startDate, end: endDate })
        .map((day) => new Date(Date.parse(day)))
        .filter((day) => {
          return (!periodConfig.weekdays || !periodConfig.weekdays.length || periodConfig.weekdays.includes(getISODay(day) - 1))
            && (!periodConfigExcludedDays || !periodConfigExcludedDays.length || !periodConfigExcludedDays.some(excludedDay => compareAbsoluteDate(excludedDay, day, "==")))
        })
        .map((day) => {
          return {
            start: zonedTimeToUtc(new Date(day.getFullYear(), day.getMonth(), day.getDate(), periodConfigStartTimeSplit[0], periodConfigStartTimeSplit[1], 0), orgaTz),
            end: add(zonedTimeToUtc(new Date(day.getFullYear(), day.getMonth(), day.getDate(), periodConfigEndTimeSplit[0], periodConfigEndTimeSplit[1], 0), orgaTz), { days: timeSlotOverTwoDays ? 1 : 0 })
          };
        });
    } else {
      return [];
    }
  }
};

export const periodConfigsToTimeSlots = (periodConfigs, unexpiredOnly) => {
  let timeSlots = [];
  periodConfigs?.forEach(pc => {
    timeSlots = timeSlots.concat(periodConfigToTimeSlots(pc, unexpiredOnly));
  });
  return mergeTimeSlots(timeSlots).map(ts => {
    return {
      start: ts.start.toISOString(),
      end: ts.end.toISOString()
    };
  });
};

export const periodConfigsAreOverlapping = (periodConfigA, periodConfigB) => {
  if (compareDate(new Date(Date.parse(periodConfigA.start_date)), new Date(Date.parse(periodConfigB.end_date)), ">=") ||
    compareDate(new Date(Date.parse(periodConfigB.start_date)), new Date(Date.parse(periodConfigA.end_date)), ">=")) {
    return true;
  } else {
    return false;
  }
};

export const mergeTimeSlots = (timeSlots) => {
  if (!timeSlots || !timeSlots.length) {
    return [];
  }

  timeSlots = timeSlots
    .map(ts => { return { start: new Date(ts.start), end: new Date(ts.end) } })
    .sort((ts1,ts2) => ts1.start <= ts2.start ? ts1.start === ts2.start ? 0 : -1 : 1);

  let mergedTimeSlots = [timeSlots.shift()];

  timeSlots.forEach(ts => {
    let lastMergedTs = mergedTimeSlots[mergedTimeSlots.length-1];
    if (ts.start <= lastMergedTs.end) {
      mergedTimeSlots[mergedTimeSlots.length-1] = {
        start: lastMergedTs.start,
        end: ts.end > lastMergedTs.end ? ts.end : lastMergedTs.end
      };
    } else {
      mergedTimeSlots.push({
        start: ts.start,
        end: ts.end
      })
    }
  });

  return mergedTimeSlots;
};

export const splitTimeSlot = (timeSlot, durationTimeMinute, unexpiredOnly) =>  {
  const start = new Date(timeSlot.start);
  const end = new Date(timeSlot.end);
  let currentStart = start;
  let currentEnd = start;
  let splitTimeSlots = [];
  while (currentEnd < end) {
    currentEnd = add(currentStart, { minutes: durationTimeMinute });
    splitTimeSlots.push({
      start: currentStart,
      end: currentEnd < end ? currentEnd : end
    });
    currentStart = currentEnd
  };

  if (unexpiredOnly) {
    const now = new Date();
    splitTimeSlots = splitTimeSlots.filter(ts => ts.start >= now);
  }

  return splitTimeSlots;
};

export const removeDuplicatesTimeSlots = (arr, prop) => arr.reduce((accumulator, currentValue) => {
  if(!accumulator.find(obj => isEqual(obj.start, currentValue.start) && isEqual(obj.end, currentValue.end))){
    accumulator.push(currentValue);
  }
  return accumulator;
}, []);

export const groupTimeSlotByStartDay = (timeSlots) => {
  timeSlots = timeSlots
    .map(ts => { return { ...ts, start: new Date(ts.start), end: new Date(ts.end) } })
    .sort((ts1,ts2) => ts1.start <= ts2.start ? ts1.start === ts2.start ? 0 : -1 : 1);

  let timeSlotByStartDay = {};
  timeSlots.forEach(ts => {
    timeSlotByStartDay[[getYear(ts.start), getMonth(ts.start), getDate(ts.start)]] = [];
  });
  timeSlots.forEach(ts => {
    timeSlotByStartDay[[getYear(ts.start), getMonth(ts.start), getDate(ts.start)]] = [...timeSlotByStartDay[[getYear(ts.start), getMonth(ts.start), getDate(ts.start)]], ts];
  });

  return timeSlotByStartDay
};

export const dateToStringKey = (date) => {
  return [[getYear(date), getMonth(date), getDate(date)]];
};

export const stringKeyToDate = (stringKey) => {
  return new Date(stringKey.split(',')[0], stringKey.split(',')[1], stringKey.split(',')[2]);
};

export const timeSlotDateToTimeSlotString = (timeSlot) => {
  return getHours(timeSlot.start).toString().padStart(2, '0') + ":" + getMinutes(timeSlot.start).toString().padStart(2, '0') + ' - ' +
    getHours(timeSlot.end).toString().padStart(2, '0') + ":" + getMinutes(timeSlot.end).toString().padStart(2, '0');
};
