import produce from "immer";
import { negotiateLanguages } from "@fluent/langneg";

import * as actions from "gadget_v2/actions/session";
import { USER_FETCH_SUCCESS } from "gadget_v2/actions/user";
import { WEBSTORE_FETCH_ERROR } from "gadget_v2/actions/webstore";
import { EMPTY_CART, EXPIRED_CART, SYNCHRONIZE_CART_SUCCESS } from "gadget_v2/actions/cart";
import { DEFAULT_LOCALE, LOCALE_FILES } from "gadget_v2/locale/config";

const initialState = {
  language: { accepted: [], current: null },
  isAuthenticated: false,
  organizationId: null,
  ready: false,
  storeUUID: null,
  cartUUID: null
};

const init = produce((draft, { organizationId, sessionid, storeUUID, token, wallet, origin, language }) => {
  const requestedLanguages = language
    ? [language, ...draft.language.accepted, ...navigator.languages]
    : [...draft.language.accepted, ...navigator.languages];
  const negociatedLanguages = negotiateLanguages(
    requestedLanguages,
    [...Object.keys(LOCALE_FILES)],
    { defaultLocale: DEFAULT_LOCALE }
  );
  draft.language.accepted = negociatedLanguages;
  draft.language.current = negociatedLanguages[0];
  draft.isAuthenticated = !!((sessionid || token) && wallet);
  draft.organizationId = organizationId;
  draft.storeUUID = storeUUID;
  draft.ready = true;
  draft[organizationId] = { sessionid, token, wallet, origin };
});

const setWallet = produce((draft, { data }) => {
  if (!draft[draft.organizationId].wallet) {
    draft.isAuthenticated = true
    draft[draft.organizationId] = produce(draft[draft.organizationId], session => {
      session.wallet = data.wallet
    });
  }
});

const setReady = produce((draft) => {
  draft.ready = true;
});

const setCurrentCart = produce((draft, { data }) => {
  draft.cartUUID = data.uuid;
});

const emptyCart = produce((draft, _) => {
  draft.cartUUID = null;
});

const clear = produce((draft, _) => {
  draft.isAuthenticated = false;
  draft[draft.organizationId] = { sessionid: null, token: null, wallet: null, origin: draft[draft.organizationId].origin };
});

function session(state = initialState, action) {
  switch (action.type) {
    case USER_FETCH_SUCCESS:
      return setWallet(state, action);
    case actions.SET_SESSION:
      return init(state, action);
    case WEBSTORE_FETCH_ERROR:
      return setReady(state, action);
    case SYNCHRONIZE_CART_SUCCESS:
      return setCurrentCart(state, action);
    case EXPIRED_CART:
    case EMPTY_CART:
      return emptyCart(state, action);
    case actions.CLEAR_SESSION:
      return clear(state, action);
    default:
      return state;
  }
}

export default session;
