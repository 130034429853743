import * as actions from "gadget_v2/actions/locations";
import { parseISO } from "date-fns";
import produce from "immer";

import {
  groupTimeSlotByStartDay,
  periodConfigsToTimeSlots,
  splitTimeSlot
} from "gadget_v2/helpers/locations";

// Check if an active period exists
const checkActivePeriod = (row) => {
  const now = new Date();
  if (row.period) {
    return row.period && row.period.filter(p => {
      return p.start <= now && p.end > now
    }).length > 0;
  }
  return true;
};

// Check if currency is usables
const checkCurrency = (wallet, row) => {
  if (wallet) {
    return wallet.balances.reduce((accumulator, cg) => {
      const balances = cg.balances && cg.balances.filter(balance => balance.credit !== null).reduce((acc, balance) => {
        if (!acc.includes(balance.currency)) { acc.push(balance.currency) }
        return acc;
      }, []).filter(currency => row.currencies.includes(currency));

      if (balances.length) {
        accumulator.push(row);
      }
      return accumulator;
    }, []).length > 0;
  }
  return false;
}

// Check if wallet is include in row.wallet_group
const checkWalletGroups = (wallet, row) => {
  if (wallet && row.wallet_group) {
    return wallet.groups.map(g => g.id).includes(row.wallet_group);
  }
  return !row.wallet_group;
};

// Filter prices by currencies, wallet_groups and period
const filterPrices = (wallet, product) => product.prices.reduce((accumulator, price) => {
  const matchingRows = price.rows.filter(row => {
    return (checkCurrency(wallet, row) || price.is_default_currency_group) && checkWalletGroups(wallet, row) && checkActivePeriod(row)
  });

  if (matchingRows.length > 0) {
    accumulator.push({ ...price, rows: matchingRows })
  }
  return accumulator;
}, []).reduce((accumulator, price, _, matchingPrices) => {
  if (matchingPrices.length === 1) {
    let first = price.rows[0];
    let last = price.rows[price.rows.length - 1]
    // In case there is a mix of price rows with and without wallet group → prioritize those with a non null value
    if ((first.wallet_group && checkWalletGroups(wallet, first)) !== (last.wallet_group && checkWalletGroups(wallet, last))) {
      if (last.wallet_group && checkWalletGroups(wallet, last)) {
        [first, last] = [last, first];
      }
    }
    if (first.value !== last.value) {
      accumulator.push({ amount: last.value, decimal_places: price.decimal_places, symbol: price.symbol, default: false });
    }
    accumulator.push({ amount: first.value, decimal_places: price.decimal_places, symbol: price.symbol, default: true });
  } else {
    if (price.rows.length > 0) {
      const { value } = price.rows[0];
      accumulator.push({ amount: value, decimal_places: price.decimal_places, symbol: price.symbol, default: true });
    }
  }
  return accumulator;
}, []);

const initialState = {
  webLocationsIndex: {}, // to store all webLocations
  productsIndex: {}, // to gather all products from every webLocation
};


const init = produce((draft, { webLocations }) => {
  Object.values(webLocations).forEach((location) => {
    draft.webLocationsIndex[location.id] = { data: location, loading: true, error: null };
  });
});

const success = produce((draft, { webLocation, data, wallet }) => {
  const pages = data.pages.map(page => ({
    ...page,
    products: page.products.map(product => ({
      ...product,
      pricesToDisplay: filterPrices(wallet, product),
      prices: product.prices.map(prices => ({
        ...prices,
        rows: prices.rows.map(row => ({
          ...row,
          period: row.period && row.period.map(p => ({
            ...p,
            start: new Date(p.start),
            end: new Date(p.end)
          }))
        }))
      }))
    }))
  }));

  pages.forEach(page => {
    page.products.forEach(product => {
      if (product.variants.length > 0) {
        product.variants.forEach(variant => {
          variant.price = product.price;
          variant.prices = product.prices;
          variant.parentId = product.id;
          variant.parentIndex = [[webLocation.id, product.id]];
          variant.pricesToDisplay = filterPrices(wallet, variant);
          draft.productsIndex[[webLocation.id, variant.id]] = variant;
        });
      }
      product.type = "product";
      product.pricesToDisplay = filterPrices(wallet, product);
      draft.productsIndex[[webLocation.id, product.id]] = product;
    });
  });

  let withdrawalLocations = [];
  const now = new Date();
  data.withdrawal_locations.forEach(loc => {
    // We copy the withdrawal location object to enhance it
    let withdrawalLocation = Object.assign({}, loc);
    // We generate the timeslots from the period configs
    withdrawalLocation.withdrawal_period = periodConfigsToTimeSlots(withdrawalLocation.withdrawal_period_configs, true);
    // We split the the periods in timeslots if needed (to display choices to the user) otherwise we keep large periods (that we won't display)
    let withdrawalTimeSlots = [];
    if (withdrawalLocation.withdrawal_timeslot_duration) {
      const timeSlotDuration = withdrawalLocation.withdrawal_timeslot_duration / 60;
      withdrawalLocation.withdrawal_period.forEach(ts => {
        withdrawalTimeSlots = withdrawalTimeSlots.concat(splitTimeSlot(ts, timeSlotDuration, true));
      });
    } else {
      withdrawalTimeSlots = withdrawalLocation.withdrawal_period.filter(ts => parseISO(ts.end) >= now);
    }
    // We group the timeslots or large periods by day and we remove past periods
    withdrawalLocation.withdrawal_period = withdrawalLocation.withdrawal_period.filter(ts => parseISO(ts.end) >= now);
    withdrawalLocation.withdrawalTimeSlotsGroupByDay = groupTimeSlotByStartDay(withdrawalTimeSlots);
    // We add the enhanced withdrawalLocation in the indexed object
    withdrawalLocations.push(withdrawalLocation);
  });

  draft.webLocationsIndex[webLocation.id] = { loading: false, error: null, data: { ...data, pages, withdrawal_locations: withdrawalLocations } };
});

const error = produce((draft, { webLocation, error }) => {
  draft.webLocationsIndex[webLocation.id] = { loading: false, data: webLocation, error };
});

const locations = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOCATIONS_INIT:
      return init(state, action);
    case actions.LOCATION_FETCH_ERROR:
      return error(state, action);
    case actions.LOCATION_FETCH_SUCCESS:
      return success(state, action);
    default:
      return state;
  }
};

export default locations;
