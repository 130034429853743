export const HEADER_UPDATE = "HEADER_UPDATE";

/* Action called to update the header information and its behavior
 * If 'undefined' passed, the value will be ignored. To reset the value you have to use 'null' instead */
export const updateHeader = (title, onBack, display) => ({
  type: HEADER_UPDATE,
  title,
  onBack,
  display,
});
