export const SET_SESSION = "SET_SESSION";
export const SET_WALLET_ID = "SET_WALLET_ID";
export const CLEAR_SESSION = "CLEAR_SESSION";

export const setSession = (session) => ({
  type: SET_SESSION,
  sessionid: session.sessionid,
  token: session.token,
  wallet: session.wallet,
  origin: session.origin,
  storeUUID: session.storeUUID,
  organizationId: session.organizationId,
  language: session.language
});

export const setWalletId = (wallet) => ({
  type: SET_WALLET_ID,
  wallet
});

export const clear = (status, session) => ({
  type: CLEAR_SESSION,
  status,
  session
});