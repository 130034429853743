export const INCREMENT_PRODUCT_QUANTITY = "INCREMENT_PRODUCT_QUANTITY";
export const DECREMENT_PRODUCT_QUANTITY = "DECREMENT_PRODUCT_QUANTITY";
export const UPDATE_PRODUCT_MENU = "UPDATE_PRODUCT_MENU";
export const SUBMIT_ADDITIONAL_INFORMATION = "SUBMIT_ADDITIONAL_INFORMATION";
export const SUBMIT_WITHDRAWAL_INFORMATION = "SUBMIT_WITHDRAWAL_INFORMATION";
export const EMPTY_CART = "EMPTY_CART";
export const SYNCHRONIZE_CART_SUCCESS = "SYNCHRONIZE_CART_SUCCESS";
export const SYNCHRONIZE_CART_ERROR = "SYNCHRONIZE_CART_ERROR";
export const INIT_CART = "INIT_CART";
export const UPDATE_CART = "UPDATE_CART";
export const EXPIRED_CART = "EXPIRED_CART";
export const RESET_ERROR = "RESET_ERROR";

/* Action called at first starting of the application to retrieve current cart (trigger GET) */
export const initCart = (organizationId, storeUUID) => ({
  type: INIT_CART,
  organizationId,
  storeUUID,
});

/* Action called to update current cart (trigger PATCH) */
export const updateCart = (dataForUpdate) => ({
  type: UPDATE_CART,
  dataForUpdate
});

/* Action called to update the cart with the backend data (after POST/PATCH response) */
export const synchronizeCartSuccess = (data, pendingUpdateProcessed) => ({
  type: SYNCHRONIZE_CART_SUCCESS,
  data,
  pendingUpdateProcessed
});

/* Action called to update the cart with the backend error (after POST/PATCH response) */
export const synchronizeCartError = (res, pendingUpdateProcessed) => ({
  type: SYNCHRONIZE_CART_ERROR,
  pendingUpdateProcessed,
  error: res.response,
  status: res.status
});

/* Action called to add a product in the user cart */
export const incrementProductQuantity = (product, webLocationId, menu, idRow) => ({
  type: INCREMENT_PRODUCT_QUANTITY,
  webLocationId,
  product,
  menu,
  idRow, // For PATCH menu
});

/* Action called to remove a product from the user cart */
export const decrementProductQuantity = (product, webLocationId, menu, idRow) => ({
  type: DECREMENT_PRODUCT_QUANTITY,
  webLocationId,
  product,
  menu,
  idRow, // For PATCH menu
});

/* Action called to update a product of type menu with no changing from the user cart */
export const updateProductMenu = (product, webLocationId, menu, idRow, quantity) => ({
  type: UPDATE_PRODUCT_MENU,
  webLocationId,
  product,
  menu,
  idRow,
  quantity,
});

/* Action called to complete cart information from form  */
export const submitAdditionalInformation = (email, formData) => ({
  type: SUBMIT_ADDITIONAL_INFORMATION,
  email,
  formData
});

/* Action called to complete cart information with withdrawal information from form  */
export const submitWithdrawalInformation = (webLocation, withdrawalLocation, withdrawalTimeStart, withdrawalTimeEnd, formData) => ({
  type: SUBMIT_WITHDRAWAL_INFORMATION,
  webLocation,
  withdrawalLocation,
  withdrawalTimeStart,
  withdrawalTimeEnd,
  formData,
});


/* Action called to empty an user cart after payment and validation */
export const emptyCart = () => ({
  type: EMPTY_CART,
});

/* Action called expire and init the cart if an error has been caught */
export const expiredCart = () => ({
  type: EXPIRED_CART
});

/* Action called expire and init the cart if an error has been caught */
export const resetError = () => ({
  type: RESET_ERROR
});
