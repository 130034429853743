export default `
back-to-cashless-account = Tornar al meu compte cashless
day-monday = Dilluns
day-tuesday = Dimarts
day-wednesday = Dimecres
day-thursday = Dijous
day-friday = Divendres
day-saturday = Dissabte
day-sunday = Diumenge
not-enough-money-title = El teu saldo és insuficient
validate = Validar
welcome-to-orga = Benvingut a { $organizationId }
empty-cart-message = La teva cistella és buida
view-less = Veure menys
view-more = Veure més
view-order = Veure comanda
error-unknown = Error desconegut
error-500-title = Alguna cosa ha anat malament!
error-500-description = S'ha produït un error. Si us plau, intenta-ho de nou més tard
error-404-title = 404
error-404-description = Aquesta pàgina no existeix
error-preorder-disabled = Pre-comanda no està disponible ara mateix
error-cart-item-sold-out = Desafortunadament aquest producte no està disponible ara
sold_out = Producte agotat
no-cb-title = Targeta de crèdit no introduïda
preorder-title = Botiga online
order-history = Històric de comandes
order-history-description = Consultar, Recollir
order-history-action = { $orderNumber ->
  [one] {$orderNumber} comanda
  *[other] {$orderNumber} comandes
}
order-history-message-to-retrieve = No tens cap comanda a recollir
order-history-message-retrieved = No has recollit cap comanda
personal-infos-title = Dades personals
personal-infos-description = Estalviar temps
personal-infos-action-no-cb = Cap targeta de crèdit guardada
personal-infos-action-cb = Targeta de crèdit guardada
preorder-description = Fer una comanda, Disfrutar
preorder-description-disabled = Disponible Aviat
preorder-action-disabled-no-period = Pre-comanda no disponible
preorder-action-disabled-period = Pre-comanda tornarà a estar disponible el { DATETIME($period, day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric") }. ¡Ens veiem!
preorder-action = { $productNumber ->
  [one] { $productNumber } producte llest per a realitzar comanda
  *[other] { $productNumber } productes llestos per a realitzar comanda
}
period-config-datetime-from-to = De { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } a { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-datetime-from-until = { DATETIME($start, day: "numeric", month: "numeric", year: "numeric") }, { DATETIME($start, hour: "numeric", minute: "numeric") } - { DATETIME($end, hour: "numeric", minute: "numeric") }
period-config-datetime-from = De { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-datetime-until = Fins { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-date-interval = De DATETIME($startDate, day: "numeric", month: "numeric", year: "numeric") a DATETIME($endDate, day: "numeric", month: "numeric", year: "numeric")
your-profile = El teu perfil
name = Cognoms
first-name = Nom
email = E-mail
your-payment-method = La teva forma de pagament
save-card = Registrar una targeta de crèdit
modify = Modificar
cart-total = Total: { $articleNumber ->
  [one] {$articleNumber} producte
  *[other] {$articleNumber} productes
} { $price }
cart-total-partial = Total: { $articleNumber ->
  [one] {$articleNumber} producte
  *[other] {$articleNumber} productes
}
cart = Cistella
order-history-header-title = Gràcies per la comanda!
pickup-order = Recollir comanda
history-page-header = Històric de comandes
orders-to-pickup = Comandes per recollir ({ $quantity })
fulfilled-orders = Comandes recollides ({ $quantity })
preparing-orders = Comandes en preparació ({ $quantity })
to-prepare-orders = Comandes a preparar ({ $quantity })
other-orders = Altres comandes ({ $quantity })
possible-withdrawal-dates = Hora de recollida :
selected-withdrawal-date = Hora de recollida escollida :
selected-withdrawal-time-different-day = De { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } a { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-same-day = { DATETIME($start, day: "numeric", month: "numeric", year: "numeric") } between { DATETIME($start, hour: "numeric", minute: "numeric") } - { DATETIME($end, hour: "numeric", minute: "numeric") }
selected-withdrawal-time-start = De { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-end = Fins { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
order-summary-ref = Comanda { $orderWithdrawalRef }
order-summary-title = Resum de la comanda
order-summary-info = El codi QR està disponible en tot moment a la secció "Històric de comandes"
order-summary-info-anonymous = T'hem enviat el resum de la comanda per e-mail
order-created-date = { DATETIME($createdDate, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
order-title = Comanda { $orderWithdrawalRef }
ask-invoice = Demanar un rebut
create-invoice = Crear rebut
download-invoice = Descargar rebut
invoice-hint = Si us plau, introdueix les dades que vols que figurin al teu rebut
invoice-hint-edit = Avís: un cop validat, el rebut no podrà ser modificat
company = Empresa
vat-number-invoice = Nº IVA Intracom.
address = Adreça
postal-code = Codi postal
city = Ciutat
country = País
next = Següent
order = Demanar
order-follow-up-cta = Seguir comanda
order-header-title = Gràcies per la comanda!
order-header-already-withdrawn = Aquesta comanda ja ha estat recollida.
order-header-cancelled = Aquesta comanda s'ha cancel·lat.
order-cancelled = Comanda cancel·lada.
order-header-initiated = No ha estat possible finalitzar aquesta comanda.
order-initiated = Comanda creada pero no finalitzada.
order-header-not-fulfilled = Aquesta comanda no s'ha pogut recollir.
order-not-fulfilled = Comanda en curs de preparació.
order-header-preparing = Comanda en espera de ser preparada.
order-header-to-prepare = La teva comanda està en espera per a preparació.
 Un cop comenci la preparació es tardarà aproximadament { $preparationTime ->
  [one] {$preparationTime} minut
  *[other] {$preparationTime} minuts
} en preparar-ho.
order-to-prepare = La teva comanda està en espera per a preparació.
 Un cop comenci la preparació es tardarà aproximadament { $preparationTime ->
  [one] {$preparationTime} minut
  *[other] {$preparationTime} minuts
} en preparar-ho.
order-preparing = La comanda s'està preparant.
  Temps estimat de preparació: { $preparationTime } min.
  Hora estimada a la que estarà disponible: { DATETIME($estimationReadyTime, hour: ""numeric"", minute: ""numeric"") }.
order-status-canceled = Cancel·lat
order-status-delivered = Entregat
order-status-initiated = Pendent
order-status-not-fulfilled = Expirat
order-status-preparing = En preparació
order-status-ready = Preparat
order-status-to-deliver = A entregar
order-status-to-pay = A pagar
order-status-to-prepare = A preparar
order-status-withdrawn = Recollit
order-pick-up-mode-delivery = La teva comanda serà entregada a la teva localització per un repartidor.
pay = Pagar
payment-method-title = Mètode de pagament
create-order = Realitzar comanda
cart-checkout-header = Mètode de pagament
cart-checkout-title = Introduir les teves dades
cart-checkout-verification = Verificació del pagament
cart-checkout-error = S'ha produït un error.
cart-checkout-error-retry-with-same-card = Si us play intenta-ho de nou amb la mateixa targeta.
cart-checkout-error-retry-with-another-card = ISi us plau intenta-ho de nou amb una altra targeta.
cart-checkout-error-invalid-card-number = Revisa el número de la teva targeta.
cart-checkout-cta = Pagar { $price }
cart-validation-step-title = Informació
cart-validation-step-checkbox = He llegit la informació precedent.
cgv-step-accept = Accepto els
cgv-step-cgv = Termes i condicions d'ús
cart-topup-title = Pagar la meva comanda
cart-topup-info = Recàrrega adicional: pots recarregar mes del necessari per pagar la comanda.
cart-topup-info-sup = Nota : la quantitat mínima a recarregar és { $minAmount }.
cart-topup-cta = Validar
cart-topup-payment-title = Introduir les teves dades
cart-topup-payment-info = Completa el teu saldo per pagar la comanda.
cart-topup-payment-cta = Validar
card-enrollment-title = Introduir les teves dades
card-enrollment-cta = Guardar
close = Tancar
remove-card = La nova targeta introduida substituirà a la targeta { $masked }.
yes = Si us play intenta-ho de nou amb la mateixa targeta.
cancel = Cancel·lar
logout = Tancar sessió
home = Inici
home-idefix-title = Àrea cashless
home-idefix-description = Saldo i històric cashless
home-idefix-action = Accedir al teu espai cashless
today = Avui
input-error-email-invalid = Si us plau introdueix un e-mail vàlid.
input-error-phone-invalid = Si us plau introdueix un número de telèfon vàlid.
input-error-number-invalid = Si us plau introdueix un número vàlid.
input-error-number-too-small = El mínim és { $minValue }.
input-error-number-too-big = El màxim és { $maxValue }.
input-error-date-impossible = La data escollida no està disponible.
input-error-date-invalid = La data no és vàlida.
input-error-date-invalid-format = El format de data no és vàlid. Introdueix una data amb el format { $dateFormat }.
input-error-date-not-configured = Cap data pot ser elegida.
input-error-date-to-early = La data ha de ser posterior a { $minDate }.
input-error-date-to-late = La data ha de ser anterior a { $maxDate }.
input-error-input-too-short = Aquest camp ha de contenir al menys { $minLength } caràcters.
input-error-input-too-long = Aquest camp no pot superar els { $maxLength } caràcters.
input-error-input-empty = Aquest camp no pot estar buit.
input-error-input-invalid = El valor introduit en aquest camp no és vàlid.
input-error-input-required = Aquest camp és obligatori.
input-error-input-not-checked = Els valors introduits en els dos camps no són idèntics.
form-submit-button = Validar
form-reset-button = Reiniciar
ok = OK
required-informations = Dades obligatòries
withdrawal-date = Data de recollida
withdrawal-date-select = Tria una data
withdrawal-interval = - del { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } al { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
withdrawal-location = { $count ->
  [one] Punt de recollida
  *[other] Punts de recollida
}
withdrawal-location-not-available = Punt de recollida no disponible
withdrawal-location-time-possible = Possbiles punts de recollida i hora
withdrawal-location-value = Punt de recollida :
withdrawal-order = Recollida de la comanda
withdrawal-order-several-shop-information = Estàs comprant productes de { $shopCount } botigues. La teva comanda serà dividida en { $shopCount }.
  Si us plau tria l'hora de recollida per cada cas si és necessari.
withdrawal-products = { $productCount ->
  [one] Producte
  *[other] Productes
}
withdrawal-products-pick-up = { $productCount ->
  [one] Producte a recollir
  *[other] Productes a recollir
}
withdrawal-time = Hora de recollida
withdrawal-time-cta-expand = Veure horari d'obertura
withdrawal-timeslot = Horari de recollida
withdrawal-timeslot-select = Tria un horari
withdrawal-timeslot-select-free = Hora disponible
withdrawal-time-possible = Possible hora de recollida
withdrawn-date = Data de recollida:
withdrawn-date-formatted = { DATETIME($date, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
withdrawn-location = Punt de recollida :
cart-expired-title = La teva cistella ha expirat
cart-expired-description = La teva cistella ha expirat: ara és buida. Si us plau afegeix nous productes a la cistella.
product-cta-choose-menu = Elegir
menu-cta-add-to-cart = Afegir al cistella
menu-cta-modify = Canviar
menu-cta-modify-validate = Validar canvis
menu-error-exact-product-quantity = Has d'elegir { $quantity ->
  [one] {$quantity} producte
  *[other] {$quantity} productes
} d'aquesta categoria.
menu-error-min-product-quantity = Has d'elegir al menys { $minQuantity ->
  [one] {$minQuantity} producte
  *[other] {$minQuantity} productes
} d'aquesta categoria.
menu-see-details-cta = Veure més detalls
total = Total
cart-cta = Veure la meva cistella
or = o
preorder-not-available = Pre-comanda no està disponible ara mateix.
`;
