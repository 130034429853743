export default `
back-to-cashless-account = Zurück zu meinem Cashless-Konto
day-monday = Montag
day-tuesday = Dienstag
day-wednesday = Mittwoch
day-thursday = Donnerstag
day-friday = Freitag
day-saturday = Samstag
day-sunday = Sonntag
not-enough-money-title = Ihr Guthaben ist zu niedrig
validate = Validieren
welcome-to-orga = Willkommen bei { $organizationId }
empty-cart-message = Ihr Warenkorb ist leer
view-less = Weniger anzeigen
view-more = Mehr anzeigen
view-order = Bestellung anzeigen
error-unknown = Unbekannter Fehler
error-500-title = Ups... da ist etwas schief gelaufen!
error-500-description = Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
error-404-title = Fehler404
error-404-description = Diese Seite existiert nicht.
error-preorder-disabled = Eine Vorbestellung ist derzeit nicht möglich.
error-cart-item-sold-out = Unglücklicherweise ist dieses Produkt derzeit ausverkauft.
sold_out = Ausverkauft
no-cb-title = Unbestimmte Kreditkarte
preorder-title = Online-Vorbestellung
order-history = Bestellungsverlauf
order-history-description = Prüfen, Abholen
order-history-action = { $orderNumber ->
 [one] {$orderNumber} bestellen
 *[other] {$orderNumber} Bestellungen
}
order-history-message-to-retrieve = Sie haben keine Bestellungen zum Zuruückziehen
order-history-message-retrieved = Sie haben keinen Auftrag zurückgezogen
personal-infos-title = Persönliche Informationen
personal-infos-description = Sparen Sie Zeit
personal-infos-action-no-cb = Keine Karte gespeichert
personal-infos-action-cb = Karte gespeichert
preorder-description = Bestellen, Genießen
preorder-description-disabled = Demnächst verfügbar
preorder-action-disabled-no-period = Eine Vorbestellung ist nicht mehr möglich
preorder-action-disabled-period = Die Vorbestellung wird ab { DATETIME($period, day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric") } verfügbar sein, bis bald!
preorder-action = { $productNumber ->
 [one] { $productNumber } Produkt fertig zum Bestellen
 *[other] { $productNumber } Produkte bereit zum Bestellen
}
period-config-datetime-from-to = Von { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } bis { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-datetime-from-until = { DATETIME($start, day: "numeric", month: "numeric", year: "numeric") }, { DATETIME($start, hour: "numeric", minute: "numeric") } - { DATETIME($end, hour: "numeric", minute: "numeric") }
period-config-datetime-from = Von { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-datetime-until = Bis { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-date-interval = Von DATETIME($startDate, day: "numeric", month: "numeric", year: "numeric") to DATETIME($endDate, day: "numeric", month: "numeric", year: "numeric")
your-profile = Dein Profil
name = Nachname
first-name = Vorname
email = E-Mail
your-payment-method = Ihre Zahlungsmethode
save-card = Eine Karte speichern
modify = Bearbeiten
cart-total = Gesamt: { $articleNumber ->
 [one] {$articleNumber} Produkt
 *[other] {$articleNumber} Produkte
} { $price }
cart-total-partial = Gesamt: { $articleNumber ->
 [one] {$articleNumber} Produkt
 *[other] {$articleNumber} Produkte
}
cart = Warenkorb
order-history-header-title = Vielen Dank für Ihre Bestellungen!
pickup-order = Meine Bestellung zurücknehmen
history-page-header = Bestellverlauf
orders-to-pickup = Zu entnehmende Bestellungen ({ $quantity })
fulfilled-orders = Zurückgezogene Bestellungen ({ $quantity })
preparing-orders = Bestellungen werden vorbereitet ({ $quantity })
to-prepare-orders = Bestellungen in Vorbereitung ({ $quantity })
other-orders = Sonstige Bestellungen ({ $quantity })
possible-withdrawal-dates = Abholzeit :
selected-withdrawal-date = Ausgewählte Abholzeit :
selected-withdrawal-time-different-day = Vom { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } bis { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-same-day = { DATETIME($start, day: "numeric", month: "numeric", year: "numeric") } zwischen { DATETIME($start, hour: "numeric", minute: "numeric") } - { DATETIME($end, hour: "numeric", minute: "numeric") }
selected-withdrawal-time-start = Vom { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-end = Bis { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
order-summary-ref = Bestellung { $orderWithdrawalRef }
order-summary-title = Zusammenfassung der Bestellung
order-summary-info = Der QR-Code ist jederzeit im Bereich "Bestellverlauf" verfügbar
order-summary-info-anonymous = Ihre Bestellzusammenfassung wurde an Ihre E-Mail-Adresse gesendet
order-created-date = { DATETIME($createdDate, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
order-title = Bestellung { $orderWithdrawalRef }
ask-invoice = Fordern Sie einen Beleg an
create-invoice = Belegerstellung
download-invoice = Beleg herunterladen
invoice-hint = Bitte füllen Sie die Angaben aus, die auf Ihrer Beleg aufgedruckt sein sollen.
invoice-hint-edit = Achtung: Einmal erstellt, kann der Beleg nicht mehr bearbeitet werden.
company = Firma
vat-number-invoice = Umsatzsteuer-Identifikationsnummer
address = Adresse
postal-code = Postleitzahl
city = Stadt
country = Land
next = Weiter
order = Bestellung
order-follow-up-cta = Folgebestellung
order-header-title = Vielen Dank für Ihre Bestellungen!
order-header-already-withdrawn = Diese Bestellung ist bereits zurückgezogen worden.
order-header-cancelled = Diese Bestellung wurde storniert.
order-cancelled = Bestellung storniert.
order-header-initiated = Diese Bestellung wurde nicht validiert.
order-initiated = Bestellung nicht validiert.
order-header-not-fulfilled = Diese Bestellung ist nicht korrekt zurückgezogen worden.
order-not-fulfilled =
order-header-preparing = Diese Bestellung wird gerade bearbeitet.
order-header-to-prepare = Diese Bestellung wartet darauf, bearbeitet zu werden.
order-to-prepare = Ihre Bestellung wartet darauf, bearbeitet zu werden.
  Sobald Ihre Bestellung angenommen wurde, wird {$preparationTime ->
  [one] {$preparationTime} Minute
  * [other] {$preparationTime} Minuten
} benötigt, um sie vorzubereiten.
order-preparing = Ihre Bestellung wird gerade bearbeitet.
  Geschätzte Vorbereitungszeit : { $preparationTime } min.
  Geschätztes Fertigstellung: { DATETIME($estimationReadyTime, hour: ""numeric"", minute: ""numeric"") }.
order-status-canceled = Abgebrochen
order-status-delivered = Geliefert
order-status-initiated = Warten
order-status-not-fulfilled = Abgelaufen
order-status-preparing = Vorbereiten
order-status-ready = Fertig
order-status-to-deliver = Lieferung
order-status-to-pay = Zu bezahlen
order-status-to-prepare = Vorbereiten
order-status-withdrawn = Zurückgezogen
order-pick-up-mode-delivery = Ihre Bestellung wird von einem Zusteller an Ihren Standort geliefert.
pay = Bezahlen
payment-method-title = Zahlungsmethode
create-order = Jetzt bestellen
cart-checkout-header = Zahlungsmethode
cart-checkout-title = Geben Sie Ihre Kartendaten ein
cart-checkout-verification = Überprüfung der Zahlung
cart-checkout-error = Es ist ein Fehler aufgetreten.
cart-checkout-error-retry-with-same-card = Bitte versuchen Sie es erneut mit der gleichen Karte.
cart-checkout-error-retry-with-another-card = Bitte versuchen Sie es erneut mit einer anderen Karte.
cart-checkout-error-invalid-card-number = Bitte überprüfen Sie die Kartennummer.
cart-checkout-cta = Bezahlen { $price }
cart-validation-step-title = Information
cart-validation-step-checkbox = Ich habe die oben genannten Informationen gelesen.
cgv-step-accept = Ich akzeptiere die
cgv-step-cgv = Bedingungen und Konditionen
cart-topup-title = Meine Bestellung bezahlen
cart-topup-info = Zusätzliche Aufladung: Sie können mehr als den fehlenden Betrag aufladen, um diese Bestellung zu bezahlen.
cart-topup-info-sup = Hinweis: Der Mindestbetrag für die Cashless-Aufladung beträgt { $minAmount }.
cart-topup-cta = Validieren
cart-topup-payment-title = Geben Sie Ihre Informationen ein
cart-topup-payment-info = Füllen Sie Ihr Guthaben auf, um Ihre Bestellung zu bezahlen.
cart-topup-payment-cta = Validieren
card-enrollment-title = Geben Sie Ihre Kartendaten ein
card-enrollment-cta = Speichern
close = Schließen
remove-card = Die Karte { $masked } wird durch Ihre neue Karte ersetzt.
yes = Ja
cancel = Abbrechen
logout = Ausloggen
home = Startseite
home-idefix-title = Cashless Konto
home-idefix-description = Saldo und Cashless-Transaktionen
home-idefix-action = Zugriff auf Ihr Cashless-Konto
today = Heute
input-error-email-invalid = Bitte geben Sie eine gültige E-Mail ein.
input-error-phone-invalid = Bitte geben Sie eine gültige Telefonnummer ein.
input-error-number-invalid = Bitte geben Sie eine gültige Nummer ein.
input-error-number-too-small = Minimum ist { $minValue }.
input-error-number-too-big = Maximum ist { $maxValue }.
input-error-date-impossible = Das gewählte Datum kann nicht ausgewählt werden.
input-error-date-invalid = Das Datum ist unzulässig.
input-error-date-invalid-format = Das Datumsformat ist ungültig, sollte { $dateFormat } sein.
input-error-date-not-configured = Es kann kein Datum ausgewählt werden.
input-error-date-to-early = Das Datum sollte nach { $minDate } liegen.
input-error-date-to-late = Das Datum sollte vor { $maxDate } liegen.
input-error-input-too-short = Dieses Feld sollte mindestens { $minLength } Zeichen enthalten.
input-error-input-too-long = Dieses Feld sollte nicht mehr als { $maxLength } Zeichen enthalten.
input-error-input-empty = Das Feld darf nicht leer sein.
input-error-input-invalid = Dieses Feld ist ungültig.
input-error-input-required = Dieses Feld ist erforderlich.
input-error-input-not-checked = Beide Felder sind unterschiedlich.
form-submit-button = Absenden
form-reset-button = Zurücksetzen
ok = OK
required-informations = Notwendige Informationen
withdrawal-date = Datum der Rücknahme
withdrawal-date-select = Wählen Sie ein Datum aus
withdrawal-interval =  vom { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } bis { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
withdrawal-location = { $count ->
 [one] Abholstation
 *[other] Abholstationen
}
withdrawal-location-not-available = Kein verfügbarer Entnahmeort
withdrawal-location-time-possible = Mögliche(r) Abholort(e) und Uhrzeit
withdrawal-location-value = Abholpunkt :
withdrawal-order = Abholung der Bestellung
withdrawal-order-several-shop-information = Sie werden Produkte in { $shopCount } Shops kaufen. Ihre Bestellung wird also in { $shopCount } aufgeteilt.
  Bitte wählen Sie bei Bedarf jeweils das Zeitfenster für die Entnahme.
withdrawal-products = { $productCount ->
 [one] Produkt
 *[other] Produkte
}
withdrawal-products-pick-up = { $productCount ->
 [one] Produkt zur Abholung
 *[other] Produkte zur Abholung
}
withdrawal-time = Entnahmezeit
withdrawal-time-cta-expand = Siehe Öffnungszeiten
withdrawal-timeslot = Entnahmezeitfenster
withdrawal-timeslot-select = Wählen Sie ein Zeitfenster
withdrawal-timeslot-select-free = Freies Zeitfenster
withdrawal-time-possible = Mögliche Abholzeit
withdrawn-date = Entnahmedatum:
withdrawn-date-formatted = { DATETIME($date, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
withdrawn-location = Entnahmeort :
cart-expired-title = Ihr Warenkorb ist gerade abgelaufen
cart-expired-description = Ihr Warenkorb ist gerade abgelaufen: Er ist jetzt leer. Bitte legen Sie neue Produkte in Ihren Warenkorb.
product-cta-choose-menu = Auswählen
menu-cta-add-to-cart = Zum Warenkorb hinzufügen
menu-cta-modify = Ändern
menu-cta-modify-validate = Änderungen bestätigen
menu-error-exact-product-quantity = Sie müssen { $quantity ->
  [one] {$quantity} Produkt
  *[other] {$quantity} Produkte
} aus dieser Kategorie auswählen.
menu-error-min-product-quantity = Sie müssen mindestens { $minQuantity ->
  [one] {$minQuantity} Produkt
  *[other] {$minQuantity} Produkte
} aus dieser Kategorie wählen.
menu-see-details-cta = Mehr Details anzeigen
total = Insgesamt
cart-cta = Meinen Warenkorb ansehen
or = oder
preorder-not-available = Vorbestellungen sind derzeit nicht möglich.
`;
