import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, filter, map, mergeMap } from "rxjs/operators";

import * as actions from "gadget_v2/actions/user";
import configure from "gadget_v2/helpers/fetcher";
import { SET_SESSION } from "gadget_v2/actions/session";

export const fetchUser = (action$, state$) => action$.pipe(
  filter(({ type, sessionid, token }) => (type === SET_SESSION && (sessionid || token)) || type === actions.USER_FETCH),
  mergeMap(() => {
    const { organizationId, language } = state$.value.session;
    const session = state$.value.session[organizationId];
    return ajax(configure(session, language.current)({ resource: `/organizations/${organizationId}/client` })).pipe(
      map((res) => {
        if (res.status === 200) return actions.fetchUserSuccess(res.response)
        return actions.fetchUserError(res);
      }),
      catchError((res) => of(actions.fetchUserError(res)))
    )
  }),
);