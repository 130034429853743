import { default as en } from "./en";
import { default as fr } from "./fr";
import { default as es } from "./es";
import { default as nl } from "./nl";
import { default as fi } from "./fi";
import { default as de } from "./de";
import { default as ca } from "./ca";

export const DEFAULT_LOCALE = "en";

export const LOCALE_FILES = {
  "fr-FR": fr,
  "es-ES": es,
  "en-GB": en,
  "nl-BE": nl,
  "fi-FI": fi,
  "de-DE": de,
  "en": en,
  "fr": fr,
  "es": es,
  "ca": ca,
  "nl": nl,
  "fi": fi,
  "de": de,
};
