import * as actions from "gadget_v2/actions/webstore";

const initialState = {
  loading: true,
  data: null,
  error: null
};

const webStore = (state = initialState, action) => {
  switch (action.type) {
    case actions.WEBSTORE_FETCH_SUCCESS:
      return { ...state, loading: false, data: action.data };
    case actions.WEBSTORE_FETCH_ERROR:
      return { ...state, loading: false, error: action.error, status: action.status };
    default:
      return state;
  }
};

export default webStore;
