import produce from "immer";
import * as actions from "gadget_v2/actions/header";

const initialState = {
  title: null,
  onBack: null,
  display: false
};

const update = produce((draft, { title, onBack, display }) => {
  if (title !== undefined) {
    draft.title = title;
  }
  if (onBack !== undefined) {
    draft.onBack = onBack;
  }
  if (display !== undefined) {
    draft.display = display;
  }
});

const header = (state = initialState, action) => {
  switch (action.type) {
    case actions.HEADER_UPDATE:
      return update(state, action);
    default:
      return state;
  }
};

export default header;
