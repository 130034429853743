import {
  INIT_CART,
  UPDATE_CART,
  DECREMENT_PRODUCT_QUANTITY,
  SUBMIT_ADDITIONAL_INFORMATION,
  SUBMIT_WITHDRAWAL_INFORMATION,
  UPDATE_PRODUCT_MENU,
  EMPTY_CART,
  INCREMENT_PRODUCT_QUANTITY,
  SYNCHRONIZE_CART_SUCCESS,
  SYNCHRONIZE_CART_ERROR,
  EXPIRED_CART,
  RESET_ERROR,
} from 'gadget_v2/actions/cart';
import { applyPendingUpdateToCartProducts } from "gadget_v2/helpers/cart";
import produce from 'immer';

const initialState = {
  ready: false,
  organizationId: null,
  storeUUID: null,
  cartKey: null,
  expired: false
};

const initialCartState = {
  uuid: null,
  /* data and products to update (in current data request but not response) */
  dataForUpdate: {},
  /* actions list : processing (in current data request) and to process (in next data request)
  * pending update = action ~ pending product */
  pendingUpdate: [],
  /* products return by the backend (last data response) */
  products: {},
  productCount: 0,
  formData: null,
  email: null,
  total: null,
  actions: {},
  loading: false,
  error: null,
  data: null
};

const init = produce((draft, { organizationId, storeUUID }) => {
  draft.organizationId = organizationId;
  draft.storeUUID = storeUUID;
  draft.cartKey = [organizationId, storeUUID];
  draft[draft.cartKey] = initialCartState;
  draft.expired = false;
});

function cartReducer(state = initialState, action) {
  const cartKey = state.cartKey

  switch (action.type) {
    case INIT_CART:
      return init(state, action);
    case INCREMENT_PRODUCT_QUANTITY: {
      let productKey = [action.webLocationId, action.product.id];
      if (action.idRow) {
        productKey = [action.webLocationId, action.product.id, action.idRow];
      }

      // We take into account total quantity from confirmed products and pending products (not already confirmed by the backend)
      const currentProduct = applyPendingUpdateToCartProducts(state[cartKey].products, state[cartKey].pendingUpdate)[productKey]
      const quantity = currentProduct && currentProduct.quantity ? currentProduct.quantity : 0;
      let delta = 0;
      let productCount = state[cartKey].productCount;

      if (quantity === 0) {
        delta = action.product.min_quantity ? action.product.min_quantity : 1;
        productCount += delta;
      } else if (!action.product.max_quantity || quantity < action.product.max_quantity) {
        ++delta;
        ++productCount;
      } else {
        return state;
      }

      const newData = {
        item: action.product.id,
        web_location: action.webLocationId,
        delta: delta,
      };
      if (action.menu) {
        newData.menu = action.menu;
      }
      if (action.idRow) {
        newData.row_id = action.idRow;
      }

      return {
        ...state,
        [cartKey]: {
          ...state[cartKey],
          pendingUpdate: [
            { action: "incrementProduct", data: newData, processed: false },
            ...state[cartKey].pendingUpdate
          ],
          productCount: productCount,
        }
      };
    }
    case DECREMENT_PRODUCT_QUANTITY: {
      let productKey = [action.webLocationId, action.product.id];
      if (action.idRow) {
        productKey = [action.webLocationId, action.product.id, action.idRow];
      }

      // We take into account total quantity from confirmed products and pending products (not already confirmed by the backend)
      const currentProduct = applyPendingUpdateToCartProducts(state[cartKey].products, state[cartKey].pendingUpdate)[productKey]
      const quantity = currentProduct && currentProduct.quantity ? currentProduct.quantity : 0;
      let delta = 0;
      let productCount = state[cartKey].productCount;
      const minQuantity = action.product.min_quantity ? action.product.min_quantity : 0;

      if (quantity === 0) {
        return state;
      } else if (quantity === minQuantity) {
        delta += minQuantity
        productCount -= minQuantity
      } else {
        ++delta;
        --productCount;
      }

      const newData = {
        item: action.product.id,
        web_location: action.webLocationId,
        delta: delta,
      };
      if (action.menu) {
        newData.menu = action.menu;
      }
      if (action.idRow) {
        newData.row_id = action.idRow;
      }

      return {
        ...state,
        [cartKey]: {
          ...state[cartKey],
          pendingUpdate: [
            { action: "decrementProduct", data: newData, processed: false },
            ...state[cartKey].pendingUpdate
          ],
          productCount: productCount,
        }
      };
    }
    case UPDATE_PRODUCT_MENU: {
      let productKey = [action.webLocationId, action.product.id];
      if (action.idRow) {
        productKey = [action.webLocationId, action.product.id, action.idRow];
      }
      let productCount = state[cartKey].productCount;

      const newData = {
        item: action.product.id,
        web_location: action.webLocationId,
        menu: action.menu,
        quantity: action.quantity,
      };

      if (action.idRow) {
        newData.row_id = action.idRow;
      }

      return {
        ...state,
        [cartKey]: {
          ...state[cartKey],
          pendingUpdate: [
            { action: "updateProductMenu", data: newData, processed: false },
            ...state[cartKey].pendingUpdate
          ],
          productCount: productCount,
        }
      };
    }
    case SUBMIT_ADDITIONAL_INFORMATION:
      return {
        ...state,
        [cartKey]: {
          ...state[cartKey],
          email: action.email,
          formData: action.formData
        }
      };
    case SUBMIT_WITHDRAWAL_INFORMATION:
      return {
        ...state,
        [cartKey]: {
          ...state[cartKey],
          pendingUpdate: [
            {
              action: "addWithdrawalInformation",
              data: {
                form_data: action.formData,
                selected_withdrawal_time_start: action.withdrawalTimeStart,
                selected_withdrawal_time_end: action.withdrawalTimeEnd,
                selected_withdrawal_location: action.withdrawalLocation,
                web_location: action.webLocation,
              },
              processed: false
            },
            ...state[cartKey].pendingUpdate
          ],
        }
      };
    case UPDATE_CART:
      let pendingUpdateNew = [...state[cartKey].pendingUpdate];
      const length = pendingUpdateNew.filter(update => update.processed === false).length;
      const firstIndexToUpdate = length - action.dataForUpdate.pendingUpdateProcessed;
      pendingUpdateNew
        .filter((update, index) => index >= firstIndexToUpdate && index < length)
        .forEach((update) => { update.processed = true });
      return {
        ...state,
        [cartKey]: {
          ...state[cartKey],
          dataForUpdate: action.dataForUpdate,
          pendingUpdate: pendingUpdateNew,
          loading: true,
        }
      };
    case EXPIRED_CART:
      return {
        ...state,
        expired: true,
        ready: true
      }
    case EMPTY_CART:
      return {
        ...state,
        ready: true,
        expired: false,
        [cartKey]: initialCartState
      };
    case SYNCHRONIZE_CART_SUCCESS:
      // We update the cart with backend data but we include pending products in total productCount
      const products = action.data.products.length
        ? action.data.products.reduce((acc, product) => {
          product.rows.forEach(row => {
            if (row.row_id && row.menu.length) {
              acc[[row.web_location, row.id, row.row_id]] = {
                web_location: row.web_location,
                quantity: row.quantity,
                id: row.id,
                menu: row.menu,
                row_id: row.row_id,
                form_data: product.form_data,
                selected_withdrawal_location: product.selected_withdrawal_location,
                selected_withdrawal_time_start: product.selected_withdrawal_time_start,
                selected_withdrawal_time_end: product.selected_withdrawal_time_end,
              };
            } else {
              acc[[row.web_location, row.id]] = {
                web_location: row.web_location,
                quantity: row.quantity,
                id: row.id,
                menu: row.menu,
                row_id: null,
                form_data: product.form_data,
                selected_withdrawal_location: product.selected_withdrawal_location,
                selected_withdrawal_time_start: product.selected_withdrawal_time_start,
                selected_withdrawal_time_end: product.selected_withdrawal_time_end,
              };
            }
          })
          return acc;
        }, {})
        : {};
      const pendingUpdateSynchronizeCartSuccess = action.pendingUpdateProcessed
        ? state[cartKey].pendingUpdate.slice(0, state[cartKey].pendingUpdate.length - action.pendingUpdateProcessed)
        : state[cartKey].pendingUpdate;
      const allProducts = applyPendingUpdateToCartProducts(products, pendingUpdateSynchronizeCartSuccess);

      return {
        ...state,
        ready: true,
        [cartKey]: {
          ...state[cartKey],
          uuid: action.data.uuid,
          actions: action.data._actions,
          error: null,
          email: action.data.email,
          formData: action.data.form_data,
          products: { ...products },
          dataForUpdate: {},
          pendingUpdate: pendingUpdateSynchronizeCartSuccess,
          productCount: Object.values(allProducts).reduce((acc, product) => (acc + product.quantity), 0),
          total: {
            amount: action.data.total,
            nbDecimal: action.data.currency_nb_decimal,
            symbol: action.data.currency_symbol
          },
          loading: false,
          data: action.data
        }
      };
    case SYNCHRONIZE_CART_ERROR:
      // If an error occured we removed the actions even if they failed and we correct the productCount
      const pendingUpdateSynchronizeCartError = action.pendingUpdateProcessed
        ? state[cartKey].pendingUpdate.slice(0, state[cartKey].pendingUpdate.length - action.pendingUpdateProcessed)
        : state[cartKey].pendingUpdate;
      return {
        ...state,
        ready: true,
        [cartKey]: {
          ...state[cartKey],
          error: action.error,
          dataForUpdate: {},
          pendingUpdate: pendingUpdateSynchronizeCartError,
          productCount: Object.values(state[cartKey].products).reduce((acc, product) => (acc + product.quantity), 0),
          loading: false
        }
      };
    case RESET_ERROR:
      return {
        ...state,
        [cartKey]: {
          ...state[cartKey],
          error: null,
        }
      };
    default:
      return state;
  }
}

export default cartReducer;
