export default `
back-to-cashless-account = Volver a mi cuenta cashless
day-monday = Lunes
day-tuesday = Martes
day-wednesday = Miércoles
day-thursday = Jueves
day-friday = viernes
day-saturday = Sábado
day-sunday = Domingo
not-enough-money-title = Tu saldo es insuficiente
validate = Validar
welcome-to-orga = Bienvenido a { $organizationId }
empty-cart-message = Tu carrito está vacío
view-less = Ver menos
view-more = Ver más
view-order = Ver pedido
error-unknown = Error desconocido
error-500-title = Algo ha ido mal!
error-500-description = Se ha producido un error. Por favor, vuelve a intentarlo más tarde.
error-404-title = 404
error-404-description = Esta página no existe
error-preorder-disabled = Pre-comanda no está disponible ahora mismo
error-cart-item-sold-out = Desafortunadamente este producto no está disponible ahora
sold_out = Producto agotado
no-cb-title = Tarjeta de crédito no introducida
preorder-title = Tienda online
order-history = Historial de compras
order-history-description = Consultar, Recoger
order-history-action = { $orderNumber ->
 [one] {$orderNumber} pedido
 *[other] {$orderNumber} pedidos
}
order-history-message-to-retrieve = No tienes ningún pedido que recoger
order-history-message-retrieved = No has recogido ningún pedido
personal-infos-title = Datos personales
personal-infos-description = Ganar tiempo
personal-infos-action-no-cb = Ninguna tarjeta de crédito guardada
personal-infos-action-cb = Tarjeta de crédito guardada
preorder-description = Hacer un pedido, Disfrutar
preorder-description-disabled = Pronto disponible
preorder-action-disabled-no-period Pre-comanda no disponible
preorder-action-disabled-period = El pre-order volverá a estar disponible el { DATETIME($period, day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric") }. ¡Nos vemos!
preorder-action = { $productNumber ->
 [one] { $productNumber } producto listo para realizar pedido
 *[other] { $productNumber } productos listos para realizar pedido
}
period-config-datetime-from-to = De { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } a { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-datetime-from-until = { DATETIME($start, day: "numeric", month: "numeric", year: "numeric") }, { DATETIME($start, hour: "numeric", minute: "numeric") } - { DATETIME($end, hour: "numeric", minute: "numeric") }
period-config-datetime-from = De { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-datetime-until = Hasta { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-date-interval = De DATETIME($startDate, day: "numeric", month: "numeric", year: "numeric") a DATETIME($endDate, day: "numeric", month: "numeric", year: "numeric")
your-profile = Tu perfil
name = Apellidos
first-name = Nombre
email = E-mail
your-payment-method = Tu forma de pago
save-card = Registrar una tarjeta de crédito
modify = Modificar
cart-total = Total: { $articleNumber ->
 [one] {$articleNumber} producto
 *[other] {$articleNumber} productos
} { $price }
cart-total-partial = Total: { $articleNumber ->
 [one] {$articleNumber} producto
 *[other] {$articleNumber} productos
}
cart = Carrito
order-history-header-title = ¡Gracias por tus pedidos!
pickup-order = Recoger mi pedido
history-page-header = Historial de compras
orders-to-pickup = Pedidos para recoger ({ $quantity })
fulfilled-orders = Pedidos recogidos ({ $quantity })
preparing-orders = Pedidos en curso de preparación ({ $quantity })
to-prepare-orders = Pedidos a preparar ({ $quantity })
other-orders = Otros pedidos ({ $quantity })
possible-withdrawal-dates = Hora de recogida :
selected-withdrawal-date = Hora de recogida elegida :
selected-withdrawal-time-different-day = De { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } a { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-same-day = { DATETIME($start, day: "numeric", month: "numeric", year: "numeric") } between { DATETIME($start, hour: "numeric", minute: "numeric") } - { DATETIME($end, hour: "numeric", minute: "numeric") }
selected-withdrawal-time-start = De { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-end = Hasta { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
possible-withdrawal-dates = Recogida posible:
selected-withdrawal-date =
selected-withdrawal-time-different-day =
selected-withdrawal-time-same-day =
selected-withdrawal-time-start =
selected-withdrawal-time-end =
order-summary-ref = Pedido { $orderWithdrawalRef }
order-summary-title = Resumen de la compra
order-summary-info = El código QR está disponible en todo momento desde el apartado "Historial de compras"
order-summary-info-anonymous = Te hemos enviado el resumen de tu pedido  por e-mail
order-created-date = { DATETIME($createdDate, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
order-title = Pedido { $orderWithdrawalRef }
ask-invoice = Solicitar un recibo
create-invoice = Generación de un recibo
download-invoice = Descargar el recibo
invoice-hint = Por favor, introduce los datos que quieres que figuren en el recibo.
invoice-hint-edit = Aviso: una vez validado, el recibo no se podrá modificar.
company = Empresa
vat-number-invoice = N° IVA Intracom.
address = Dirección
postal-code = Código postal
city = Ciudad
country = País
next = Siguiente
order = Pedir
order-follow-up-cta = Seguir pedido
order-header-title = ¡Gracias por tu compra!
order-header-already-withdrawn = Este pedido ya se ha recogido.
order-header-cancelled = Este pedido se ha cancelado.
order-cancelled = Pedido cancelado.
order-header-initiated = No ha sido posible finalizar este pedido.
order-initiated = Pedido creado pero no finalizado.
order-header-not-fulfilled = Este pedido no se ha podido recoger.
order-not-fulfilled = 
order-header-preparing = Pedido en curso de preparación.
order-header-to-prepare = Pedido en espera para su preparación.
order-to-prepare = Tu pedido está en espera para su preparación.
 Una vez se comience la preparacón se tardará aproximadamente { $preparationTime ->
 [one] {$preparationTime} minuto
 *[other] {$preparationTime} minutos
} en prepararlo.
order-preparing = Tu pedido se está preparando.
 Tiempo estimado de preparación: { $preparationTime } min.
 Hora estimada a la que estará disponible: { DATETIME($estimationReadyTime, hour: "numeric", minute: "numeric") }.
order-status-canceled = Cancelado
order-status-delivered = Entregado
order-status-initiated = Pendiente
order-status-not-fulfilled = Expirado
order-status-preparing = En preparación
order-status-ready = Preparado
order-status-to-deliver = A entregar
order-status-to-pay = A pagar
order-status-to-prepare = A preparar
order-status-withdrawn = Recogido
order-pick-up-mode-delivery = Tu pedido será entregado en tu localización por un repartidor.
pay = Pagar
payment-method-title = Forma de pago
create-order = Realizar pedido
cart-checkout-header = Forma de pago
cart-checkout-title = Introducir tus datos
cart-checkout-verification = Verificación del pago
cart-checkout-error = Se ha producido un error.
cart-checkout-error-retry-with-same-card = Volver a intentarlo con la misma tarjeta.
cart-checkout-error-retry-with-another-card = Volver a intentarlo con otra tarjeta.
cart-checkout-error-invalid-card-number = Revisa el número de tarjeta.
cart-checkout-cta = Pagar { $price }
cart-validation-step-title = Información
cart-validation-step-checkbox = He leído la información precedente.
cgv-step-accept = Acepto los
cgv-step-cgv = Términos i condiciones de uso
cart-topup-title = Pagar mi pedido
cart-topup-info = Recarga adicional: puedes cargar más de lo necesario para pagar el pedido
cart-topup-info-sup = Nota : la cantidad mínima a cargar és { $minAmount }.
cart-topup-cta = Validar
cart-topup-payment-title = Introduce tus datos
cart-topup-payment-info = Completa tu saldo para pagar el pedido.
cart-topup-payment-cta = Validar
card-enrollment-title = Introducir tus datos
card-enrollment-cta = Guardar
close = Cerrar
remove-card = La nueva tarjeta introducida sustituirá a la tarjeta { $masked }.
yes = Sí
cancel = Cancelar
logout = Cerrar sesión
home = Inicio
home-idefix-title = Área Cashless
home-idefix-description = Saldo e historial cashless
home-idefix-action = Acceder a tu cuenta cashless
today = Hoy
input-error-email-invalid = Introduce una dirección de correo electrónico válida.
input-error-phone-invalid = Introduce un número de teléfono válido.
input-error-number-invalid = Introduce un número válido.
input-error-number-too-small = El número ha de ser igual o superior a { $minValue }.
input-error-number-too-big = El número ha de ser igual o inferior a { $maxValue }.
input-error-date-impossible = La fecha elegida es imposible.
input-error-date-invalid = La fecha no es válida.
input-error-date-invalid-format = El formato de fecha no es válido. Introduce una fecha con el formato { $dateFormat }.
input-error-date-not-configured = Ninguna fecha puede ser elegida.
input-error-date-to-early = La fecha ha de ser posterior al { $minDate }.
input-error-date-to-late = La fecha ha de ser anterior al { $maxDate }.
input-error-input-too-short = Este campo ha de contener al menos { $minLength } caracteres.
input-error-input-too-long = Este campo no puede superar los { $maxLength } caracteres.
input-error-input-empty = Este campo no puede estar vacío.
input-error-input-invalid = El valor introducido en este campo no es válido.
input-error-input-required = Este campo es obligatorio.
input-error-input-not-checked = Los valores introducidos en los dos campos no son idénticos.
form-submit-button = Validar
form-reset-button = Reiniciar
ok = OK
required-informations = Datos obligatorios
withdrawal-date = Fecha de recogida
withdrawal-date-select = Elige una fecha
withdrawal-interval = - del { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } al { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
withdrawal-location = { $count ->
 [one] Lugar de recogida
 *[other] Lugares de recogida
}
withdrawal-location-not-available = lugar de recogida no disponible
withdrawal-location-time-possible = Posibles puntos de recogida y hora
withdrawal-location-value = Lugar de recogida:
withdrawal-order = Recogida del pedido
withdrawal-order-several-shop-information = Estás comprando productos de { $shopCount } tiendas. Tu pedido será dividido en { $shopCount }.
  Por favor elige la hora de recogida en cada caso de ser necesario.
withdrawal-products = { $productCount ->
  [one] Producto
  *[other] Productos
}
withdrawal-products-pick-up = { $productCount ->
  [one] Producto a recoger
  *[other] Productos a recoger
}
withdrawal-time = Hora de recogida
withdrawal-time-cta-expand = Ver horarios de apertura
withdrawal-timeslot = Horario de recogida
withdrawal-timeslot-select = Elige un horario
withdrawal-timeslot-select-free = Hora disponible
withdrawal-time-possible = Posible hora de recogida
withdrawn-date = Fecha de recogida:
withdrawn-date-formatted = { DATETIME($date, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
withdrawn-location = Lugar de recogida:
cart-expired-title = Tu carrito ha expirado
cart-expired-description = Tu carrito ha expirado: ahora está vacío. Por favor añade nuevos productos al carrito.
product-cta-choose-menu = Escoger
menu-cta-add-to-cart = Añadir al carrito
menu-cta-modify = Cambiar
menu-cta-modify-validate = Validar cambios
menu-error-exact-product-quantity = Tienes que elegir { $minQuantity ->
  [one] {$minQuantity} producto
  *[other] {$minQuantity} productos
} de esta categoría.
menu-error-min-product-quantity = Tienes que elegir al menos { $minQuantity ->
  [one] {$minQuantity} producto
  *[other] {$minQuantity} productos
} de esta categoría.
menu-see-details-cta = Ver más detalles
total = Total
cart-cta = Ver mi carrito
or = o
preorder-not-available = Pre-comanda no está disponible ahora mismo.
`;
