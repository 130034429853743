export const LOCATIONS_INIT = "LOCATIONS_INIT";
export const LOCATION_FETCH = "LOCATION_FETCH";
export const LOCATION_FETCH_SUCCESS = "LOCATION_FETCH_SUCCESS";
export const LOCATION_FETCH_ERROR = "LOCATION_FETCH_ERROR";

export const initLocations = (webLocations) => ({
  type: LOCATIONS_INIT,
  webLocations,
});

export const fetchLocation = (webLocation) => ({
  type: LOCATION_FETCH,
  webLocation,
});

export const fetchLocationSuccess = (webLocation, data, wallet) => ({
  type: LOCATION_FETCH_SUCCESS,
  webLocation,
  data,
  wallet
});

export const fetchLocationError = (webLocation, res) => ({
  type: LOCATION_FETCH_ERROR,
  webLocation,
  error: res.response,
  status: res.status
});