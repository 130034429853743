export default `
back-to-cashless-account = Back to my cashless account
day-monday = Monday
day-tuesday = Tuesday
day-wednesday = Wednesday
day-thursday = Thursday
day-friday = Friday
day-saturday = Saturday
day-sunday = Sunday
not-enough-money-title = Your balance is too low
validate = Validate
welcome-to-orga = Welcome to { $organizationId }
empty-cart-message = Your cart is empty
view-less = View less
view-more = View more
view-order = View order
error-unknown = Unknown error
error-500-title = Oops... something went wrong !
error-500-description = An error has occured, please try again later
error-404-title = 404
error-404-description = This page does not exist
error-preorder-disabled = Preorder is not currently available
error-cart-item-already-in-wallet = You reached the maximum limit to buy this product.
error-cart-item-already-in-wallet-cta = Back home
error-cart-item-not-available = You cannot add this product into your cart.
error-cart-item-sold-out = Unfortunately, this item is now sold out
sold_out = Sold out
no-cb-title = Unspecified credit card
preorder-title = Online preorder
order-history = Order history
order-history-description = Check, Pick-up
order-history-action = { $orderNumber ->
 [one] {$orderNumber} order
 *[other] {$orderNumber} orders
}
order-history-message-to-retrieve = You have no orders to withdraw
order-history-message-retrieved = You have not withdrawn any order
personal-infos-title = Personal informations
personal-infos-description = Save time
personal-infos-action-no-cb = No card saved
personal-infos-action-cb = Card saved
preorder-description = Order, Enjoy
preorder-description-disabled = Available soon
preorder-action-disabled-no-period = Preorder is not available anymore
preorder-action-disabled-period = Preorder will be available starting with { DATETIME($period, day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric") }, see you soon!
preorder-action = { $productNumber ->
 [one] { $productNumber } product ready to order
 *[other] { $productNumber } products ready to order
}
period-config-datetime-from-to = From { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } to { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-datetime-from-until = { DATETIME($start, day: "numeric", month: "numeric", year: "numeric") }, { DATETIME($start, hour: "numeric", minute: "numeric") } - { DATETIME($end, hour: "numeric", minute: "numeric") }
period-config-datetime-from = From { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-datetime-until = Until { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-date-interval = From DATETIME($startDate, day: "numeric", month: "numeric", year: "numeric") to DATETIME($endDate, day: "numeric", month: "numeric", year: "numeric")
your-profile = Your profile
name = Last Name
first-name = First Name
email = Email
your-payment-method = Your Payment Method
save-card = Save a card
modify = Modify
cart-total = Total: { $articleNumber ->
 [one] {$articleNumber} product
 *[other] {$articleNumber} products
} { $price }
cart-total-partial = Total: { $articleNumber ->
 [one] {$articleNumber} product
 *[other] {$articleNumber} products
}
cart = Cart
order-history-header-title = Thank you for your orders!
pickup-order = Withdraw my order
history-page-header = Order history
orders-to-pickup = Orders to withdraw ({ $quantity })
fulfilled-orders = Withdrawn orders ({ $quantity })
preparing-orders = Orders being prepared ({ $quantity })
to-prepare-orders = Orders waiting preparation ({ $quantity })
general-orders = Orders ({ $quantity })
other-orders = Other orders ({ $quantity })
possible-withdrawal-dates = Pick-up time :
selected-withdrawal-date = Selected pick-up time :
selected-withdrawal-time-different-day = From { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } until { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-same-day = { DATETIME($start, day: "numeric", month: "numeric", year: "numeric") } between { DATETIME($start, hour: "numeric", minute: "numeric") } - { DATETIME($end, hour: "numeric", minute: "numeric") }
selected-withdrawal-time-start = From { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-end = Until { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
order-summary-ref = Order { $orderWithdrawalRef }
order-summary-title = Order summary
order-summary-info = The QR code is available all the time in the "Order history" section
order-summary-info-anonymous = Your order summary was sent at your email address
order-created-date = { DATETIME($createdDate, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
order-title = Order { $orderWithdrawalRef }
ask-invoice = Ask for a receipt
create-invoice = Receipt creation
download-invoice = Download receipt
invoice-hint = Please fill the details that should be printed on your receipt.
invoice-hint-edit = Warning: once created, the receipt cannot be edited.
company = Company
vat-number-invoice = VAT Number
address = Address
postal-code = Postal code
city = City
country = Country
next = Next
order = Order
order-follow-up-cta = Follow-up order
order-header-title = Thank you for your order !
order-header-already-withdrawn = This order already has been withdrawn.
order-header-cancelled = This order has been cancelled.
order-cancelled = Order cancelled.
order-header-initiated = This order was not validated.
order-initiated = Order not validated.
order-header-not-fulfilled = This order has not been withdrawn correctly.
order-not-fulfilled = Order not withdrawn
order-header-preparing = This order is being processed.
order-header-to-prepare = This order is waiting to be processed.
order-to-prepare = Your order is waiting to be processed.
 Once your order take into account { $preparationTime ->
 [one] {$preparationTime} minute
 *[other] {$preparationTime} minutes
} would be required to prepare it.
order-preparing = Your order is being processed.
 Estimated preparation time : { $preparationTime } min.
 Estimated ready date : { DATETIME($estimationReadyTime, hour: "numeric", minute: "numeric") }.
order-status-canceled = Cancelled
order-status-delivered = Delivered
order-status-initiated = Waiting
order-status-not-fulfilled = Expired
order-status-preparing = Preparing
order-status-ready = Ready
order-status-to-deliver = To deliver
order-status-to-pay = To pay
order-status-to-prepare = To prepare
order-status-withdrawn = Withdrawn
order-pick-up-mode-delivery = Your order will be delivered at your location by a delivery man.
pay = Pay
payment-method-title = Payment method
create-order = Order now
cart-checkout-header = Payment method
cart-checkout-title = Fill your card information
cart-checkout-verification = Payment verification
cart-checkout-error = An error occured.
cart-checkout-error-retry-with-same-card = Please try again with the same card.
cart-checkout-error-retry-with-another-card = Please try again with another card.
cart-checkout-error-invalid-card-number = Please check your card number.
cart-checkout-cta = Pay { $price }
cart-validation-step-title = Information
cart-validation-step-checkbox = I have read the information above regarding my order pick-up.
cgv-step-accept = I accept the
cgv-step-cgv = Terms and Conditions
cart-topup-title = Pay my order
cart-topup-info = Additional topup: you can topup more than the missing amount to pay this order.
cart-topup-info-sup = Note : the minimum cashless topup amount is { $minAmount }.
cart-topup-cta = Validate
cart-topup-payment-title = Enter your information
cart-topup-payment-info = Complete your balance to pay your order.
cart-topup-payment-cta = Validate
card-enrollment-title = Fill in your card information
card-enrollment-cta = Save
close = Close
remove-card = The card { $masked } will be replaced by your new card.
yes = Yes
cancel = Cancel
logout = Logout
home = Home
home-idefix-title = Cashless account
home-idefix-description = Balance and cashless transactions
home-idefix-action = Access your cashless account
today = Today
input-error-address-invalid = Please enter a valid address.
input-error-email-invalid = Please enter a valid email.
input-error-phone-invalid = Please enter a valid phone number.
input-error-number-invalid = Please enter a valid number.
input-error-number-too-small = Minimum is { $minValue }.
input-error-number-too-big = Maximum is { $maxValue }.
input-error-date-impossible = The chosen date cannot be selected.
input-error-date-invalid = The date is invalid.
input-error-date-invalid-format = The date format is invalid, should be { $dateFormat }.
input-error-date-not-configured = No date can be selected.
input-error-date-to-early = The date should be after { $minDate }.
input-error-date-to-late = The date should be before { $maxDate }.
input-error-input-too-short = This field should contain at least { $minLength } characters.
input-error-input-too-long = This field should not contain more than { $maxLength } characters.
input-error-input-empty = This field should not be empty.
input-error-input-invalid = This field is invalid.
input-error-input-required = This field is required.
input-error-input-not-checked = Both fields are different.
form-submit-button = Submit
form-reset-button = Reset
ok = OK
required-informations = Required informations
withdrawal-date = Withdrawal date
withdrawal-date-select = Select a date
withdrawal-interval = - from { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } until { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
withdrawal-location = { $count ->
 [one] Pick-up point
 *[other] Pick-up points
}
withdrawal-location-not-available = No available withdrawal location
withdrawal-location-time-possible = Possible pick-up location(s) and time
withdrawal-location-value = Pick-up point :
withdrawal-order = Order pick-up
withdrawal-order-additional-information = Order additional information
withdrawal-order-pick-up-and-delivery = Order pick-up and delivery
withdrawal-order-several-shop-information = You will buy products on { $shopCount } shops. So your order will be split in { $shopCount }.
  Please choose the withdrawal time slot for each one if required.
withdrawal-products = { $productCount ->
  [one] Product
  *[other] Products
}
withdrawal-products-pick-up = { $productCount ->
 [one] Product to pick-up
 *[other] Products to pick-up
}
withdrawal-time = Withdrawal time
withdrawal-time-cta-expand = See opening hours
withdrawal-timeslot = Withdrawal time slot
withdrawal-timeslot-select = Select a time slot
withdrawal-timeslot-select-free = Free time slot
withdrawal-time-possible = Possible pick-up time
withdrawn-date = Withdrawal date:
withdrawn-date-formatted = { DATETIME($date, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
withdrawn-location = Withdrawal location :
cart-expired-title = Your cart has just expired
cart-expired-description = Your cart has just expired: it is now empty. Please add new products to your cart.
product-cta-choose-menu = Select
menu-cta-add-to-cart = Add to cart
menu-cta-modify = Modify
menu-cta-modify-validate = Validate modifications
menu-error-exact-product-quantity = You have to choose { $quantity ->
 [one] {$quantity} product
 *[other] {$quantity} products
} from this category.
menu-error-min-product-quantity = You have to choose at least { $minQuantity ->
 [one] {$minQuantity} product
 *[other] {$minQuantity} products
} from this category.
menu-see-details-cta = See more details
total = Total
cart-cta = View my cart
or = or
preorder-not-available = Preorder is currently unavailable.
`;
