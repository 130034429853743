export default `
back-to-cashless-account = Terug naar mijn cashless account
day-monday = Maandag
day-tuesday = Dinsdag
day-wednesday = Woensdag
day-thursday = Donderdag
day-friday = Vrijdag
day-saturday = Zaterdag
day-sunday = Zondag
not-enough-money-title = Uw balans is ontoereikend
validate = Bevestig
welcome-to-orga = Welkom in { $organizationId }
empty-cart-message = Uw winkelmandje is leeg
view-less = Zie minder
view-more = Zie meer
view-order = Bestelling bekijken
error-unknown = Onbekende fout
error-500-title = Oeps! Er is iets misgegaan !
error-500-description = Er heeft zich een probleem voorgedaan
error-404-title = 404
error-404-description = Deze pagina bestaat niet
error-preorder-disabled = Pre-order is tijdelijk niet beschikbaar
error-cart-item-sold-out = Het product dat u heeft toegevoegd
sold_out = Uitverkocht
no-cb-title = Niet gespecificeerde kredietkaart
preorder-title = Online pre-order
order-history = Bestelgeschiedenis
order-history-description = Check
order-history-action = { $orderNumber ->
  [one] { $orderNumber } bestelling
  *[other] { $orderNumber } bestellingen
}
order-history-message-to-retrieve = U heeft geen bestellingen om af te halen
order-history-message-retrieved = U heeft nog geen enkele bestelling afgehaald
personal-infos-title = Persoonlijke informatie
personal-infos-description = Sla tijd op
personal-infos-action-no-cb = Geen bankkaart opgeslagen
personal-infos-action-cb = Bankkaart opgeslagen
preorder-description = Bestel
preorder-description-disabled = Binnenkort verkrijgbaar
preorder-action-disabled-no-period = Pre-order is niet meer beschikbaar
preorder-action-disabled-period = Pre-order zal beschikbaar zijn op { DATETIME($period, day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric") }, tot binnenkort!
preorder-action = { $productNumber ->
  [one] { $productNumber } product klaar om te bestellen
  *[other] { $productNumber } producten klaar om te bestellen
}
your-profile = Uw profiel
name = Achternaam
first-name = Voornaam
email = E-mail
your-payment-method = Uw betaalmethode
save-card = Sla bankkaart op
modify = Aanpassen
cart-total = Totaal: { $articleNumber ->
  [one] { $articleNumber } product
  *[other] { $articleNumber } producten
} { $price }
cart-total-partial = Totaal: { $articleNumber ->
  [one] { $articleNumber } product
  *[other] { $articleNumber } producten
}
cart = Winkelmand
order-history-header-title = Bedankt voor uw bestelling!
pickup-order = Haal mijn bestelling op
history-page-header = Bestelgeschiedenis
orders-to-pickup = Af te halen bestellingen ({ $quantity })
fulfilled-orders = Afgehaalde bestellingen ({ $quantity })
preparing-orders = Bestellingen in bereiding ({ $quantity })
to-prepare-orders = Bestellingen in afwachting van bereiding ({ $quantity })
other-orders = Andere bestellingen ({ $quantity })
possible-withdrawal-dates = Afhaaltijd
selected-withdrawal-date = Geselecteerde afhaaltijd
selected-withdrawal-time-different-day = Van { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } tot { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-same-day = { DATETIME($start, day: "numeric", month: "numeric", year: "numeric") } tussen { DATETIME($start, hour: "numeric", minute: "numeric") } - { DATETIME($end, hour: "numeric", minute: "numeric") }
selected-withdrawal-time-start = Van { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-end = Tot { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
order-summary-ref = Bestelling { $orderWithdrawalRef } - { $totalPrice }
order-summary-title = Besteloverzicht
order-summary-info = De QR-code is altijd beschikbaar in de "bestelgeschiedenis"
order-summary-info-anonymous = Uw besteloverzicht werd ook naar uw e-mailadres verzonden
order-created-date = { DATETIME($createdDate, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
order-title = Bestelling { $orderWithdrawalRef }
ask-invoice = Vraag een kassaticket
create-invoice = Kassaticket aanmaken
download-invoice = Download kassaticket
invoice-hint = Vul de gegevens in dat op uw kassaticket moet geprint worden
invoice-hint-edit = Let op: eenmaal aangemaakt
company = Bedrijf
vat-number-invoice = BTW-nummer
address = Adres
postal-code = Postcode
city = Stad
country = Land
next = Volgende
order = Bestelling
order-follow-up-cta = Follow-up order
order-header-title = Bedankt voor uw bestelling!
order-header-already-withdrawn = Deze bestelling werd afgehaald
order-header-cancelled = Deze bestelling werd geannuleerd
order-cancelled = Bestelling geannuleerd
order-header-initiated = Deze bestelling werd niet bevestigd
order-initiated = Bestelling niet gevalideerd
order-header-not-fulfilled = Deze bestelling werd niet correct afgehaald
order-not-fulfilled = Bestelling niet afgehaald.
order-header-preparing = Deze bestelling wordt verwerkt
order-header-to-prepare = Deze bestelling wordt binnenkort verwerkt
order-to-prepare = Uw bestelling is wachtende om verwerkt te worden. Zodra uw bestelling is afgehandeld ongeveer { $preparationTime ->
  [one] { $preparationTime } minuut
  *[other] { $preparationTime } minuten
} zijn nodig voor het klaarmaken.
order-preparing = Uw bestelling wordt verwerkt
  Geschatte bereidingstijd : { $preparationTime } min.
  Geschatte datum tot uw bestelling klaar is : { DATETIME($estimationReadyTime, hour: "numeric", minute: "numeric") }.
order-status-canceled = Geannuleerd
order-status-delivered = Gelevered
order-status-initiated = Wachtende
order-status-not-fulfilled = Verlopen
order-status-preparing = In bereiding
order-status-ready = Klaar
order-status-to-deliver = Te leveren
order-status-to-pay = Te betalen
order-status-to-prepare = Voor te bereiden
order-status-withdrawn = Afhalen
order-pick-up-mode-delivery = Uw bestelling wordt geleverd
pay = Betaal
payment-method-title = Betaalmethode
create-order = Bestel nu
cart-checkout-header = Betaalmethode
cart-checkout-title = Vul uw bankkaart gegevens in
cart-checkout-verification = Betalingsverificatie
cart-checkout-error = Er heeft zich een fout voorgedaan
cart-checkout-error-retry-with-same-card = Probeer nog eens met dezelfde bankkaart
cart-checkout-error-retry-with-another-card = Probeer nog eens met een andere bankkaart
cart-checkout-error-invalid-card-number = Kijk uw kaartnummer na
cart-checkout-cta = Betaal { $price }
cart-validation-step-title = Informatie
cart-validation-step-checkbox = Ik heb de bovenstaande informatie gelezen
cgv-step-accept = ik accepteer de
cgv-step-cgv = Voorwaarden
cart-topup-title = Betaal
cart-topup-info = Extra opwaarderen: u kan meer opwaarderen dan het ontbrekende bedrag om deze bestelling te betalen.
cart-topup-info-sup = Het minimum bedrag om op te waarderen is { $minAmount }.
cart-topup-cta = Valideren
cart-topup-payment-title = Vul je gegevens in
cart-topup-payment-info = Vul uw saldo in om uw bestelling te betalen.
cart-topup-payment-cta = Valideren
card-enrollment-title = Voer uw gegevens in
card-enrollment-cta = Bewaar
close = Sluit
remove-card = De betaalkaart { $masked } zal vervangen worden met uw nieuwe kaart
yes = Ja
cancel = Annuleer
logout = Uitloggen
home = Thuis
home-idefix-title = Cashless account
home-idefix-description = Balans en cashless transacties
home-idefix-action = Bekijk uw cashless account
today = Vandaag
input-error-email-invalid = Vul een geldig e-mailadres in
input-error-phone-invalid = Vul een geldig telefoonnummer in
input-error-number-invalid = Vul een geldig nummer in
input-error-number-too-small = Minimum is { $minValue }.
input-error-number-too-big = Maximum is { $maxValue }.
input-error-date-impossible = De gekozen datum kan niet geselecteerd worden
input-error-date-invalid = Datum is niet geldig
input-error-date-invalid-format = De datumnotatie is niet geldig. Vul in volgens het voorbeeld { $dateFormat }.
input-error-date-not-configured = Er kan geen datum geselecteerd worden
input-error-date-to-early = De datum moet zich na { $minDate } bevinden.
input-error-date-to-late = De datum moet zich voor { $maxDate } bevinden.
input-error-input-too-short = Dit veld moet minstens { $minLength } tekens bevatten
input-error-input-too-long = Dit veld mag niet meer tekens bevatten dan { $maxLength }
input-error-input-empty = Dit veld mag niet leeg zijn
input-error-input-invalid = Dit veld is niet geldig
input-error-input-required = Dit veld is verplicht
input-error-input-not-checked = De twee velden zijn niet identiek.
form-submit-button = Bevestig
form-reset-button = Herstel
ok = OK
required-informations = Verplichte informatie
withdrawal-date = Afhaaldatum
withdrawal-date-select = Selecteer een datum
withdrawal-interval = - van { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } tot { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
withdrawal-location = { $count ->
  [one] Afhaallocatie
  *[other] Afhaallocaties
}
withdrawal-location-not-available = Geen afhaallocatie beschikbaar
withdrawal-location-time-possible = Mogelijke afhaallocatie(s) en tijd
withdrawal-location-value = Afhaallocatie:
withdrawal-order = Bestelling intrekken
withdrawal-order-several-shop-information = U heeft uw aankopen gedaan bij {$ shopCount} winkels. Uw bestelling wordt daarom opgesplitst in {$ shopCount}.
Selecteer indien nodig voor elke bestelling een afhaaltijdslot.
withdrawal-products = { $productCount ->
  [one] aankoop
  *[other] aankopen
}
withdrawal-products-pick-up = { $productCount ->
  [one] aankoop af te halen
  *[other] aankopen af te halen
}
withdrawal-time = Afhaaltijd
withdrawal-time-cta-expand = Bekijk openingsuren
withdrawal-timeslot = Afhaaltijdslot
withdrawal-timeslot-select = Selecteer een tijdslot
withdrawal-timeslot-select-free = Gratis Tijdslot
withdrawal-time-possible = Mogelijke afhaaltijd
withdrawn-date = Afhaaldatum:
withdrawn-date-formatted = { DATETIME($date, hour: "numeric", minute: "numeric") }
withdrawn-location = Afhaallocatie:
cart-expired-title = Uw winkelmandje is verlopen
cart-expired-description = Uw winkelmandje is verlopen: deze is nu leeg. Selecteer nieuwe producten
product-cta-choose-menu = Selecteer
menu-cta-add-to-cart = Voeg toe aan winkelmandje
menu-cta-modify = Aanpassen
menu-cta-modify-validate = Valideer aanpassing
menu-error-exact-product-quantity = Je moet kiezen uit { $quantity ->
  [one] {$quantity} product
  *[other] {$quantity} producten
} uit deze categorie.
menu-error-min-product-quantity = Je moet kiezen uit minstens { $minQuantity ->
  [one] {$minQuantity} product
  *[other] {$minQuantity} producten
} uit deze category.
menu-see-details-cta = Bekijk details
total = Totaal
cart-cta = Bekijk mijn winkelwagen
or = of
preorder-not-available = Pre-order is momenteel onbeschikbaar
`;
