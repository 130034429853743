import { combineReducers } from "redux";
import cartReducer from "./cart";
import headerReducer from "./header";
import locationsReducer from "./locations";
import webStoreReducer from "./webstore";
import userReducer from "./user";
import sessionReducer from "./session";
import walletReducer from "./wallet";

const reducers = combineReducers({
  cart: cartReducer,
  header: headerReducer,
  locations: locationsReducer,
  webStore: webStoreReducer,
  user: userReducer,
  session: sessionReducer,
  wallet: walletReducer,
});

export default reducers;
