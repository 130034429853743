// eslint-disable-next-line import/named
import { removeHeader } from "libs/api/interceptors";

// This is to call the API without any invalid credentials. Since this endpoint is open.
const UNAUTHORIZED_INTERCEPTOR = removeHeader("Authorization");

export default function receipt(client) {
  const path = "/receipt";
  return {
    get: (data) => client.request({
      endpoint: `${path}?data=${data}`,
      method: "GET",
      interceptors: [
        UNAUTHORIZED_INTERCEPTOR,
      ],
    }),
    getOrder: () => ({
      reference: "T12234",
      state: "STATE_SYNCED", // or 'STATE_WAITING_FISCALISATION' or 'STATE_WAITING_SYNC'
      total_price_with_vat: 2200,
      created: "2021-09-01T12:00:00",
      location_name: "McDonalds",
      currency: {
        symbol: "€",
        decimal_places: 2,
      },
      rows: [{
        quantity: 2,
        unit_price: 1100,
        price: 2200,
        name: "MENU_HAPPY",
        amount_vat: 200,
        vat: 10,
        unit_price_ex_vat: 1000,
        menu_choices: [
          {
            name: "COCA",
            extra_price_amount: null,
          },
          {
            name: "Burger",
            extra_price_amount: 200,
          },
        ],
      }],
      totals: { // # Actually currency group but let's not propagate Gill bad naming
        total: 2200,
        vat_totals: [{
          rate: 10,
          amount: 200,
        }],
      },
      payments: [
        // # CASHLESS PAYMENT (balance is not None)
        {
          type: "CASHLESS",
          id: 1,
          amount: 1000,
          data: {
            wallet_owner_name: "John Doe", // # or None
            wallet_owner_email: "john.doe@weez.com", // # or None
          },
        },
        // # NOT LINKED PMO (pmo is not None but payment is None)
        {
          id: 2,
          type: "CASH", // # Name of the PMO not linked to a psp
          amount: 1000,
          data: null, // {} or None  // # whatever is easier
        },
        // # LINKED PMO (pmo is not None AND payment is not None)
        {
          id: 3,
          type: "CB_Nepting", // # Name of the PMO
          amount: 1000,
          data: {
            payment_reference: "HFEH8FYH83HF39BF389NF3",
            masked_card_number: "1234-XXXX-XXXX-XXXX",
          },
        },
        {
          id: 4,
          type: "CB_Nepting", // # Name of the PMO
          amount: 10000,
          data: {},
        },
        {
          id: 5,
          type: "CASHLESS", // # Name of the PMO
          amount: 12345,
          data: {},
        },
      ],
    }),
    sendByEmail: (email, data) => client.request({
      endpoint: `${path}?data=${data}`,
      method: "POST",
      body: { email_address: email },
      interceptors: [
        UNAUTHORIZED_INTERCEPTOR,
      ],
    }),
  };
}
