import { UPDATE_PRODUCT_MENU } from "gadget_v2/actions/cart";
import { ofType } from "redux-observable";
import { of, EMPTY } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, map, mergeMap, withLatestFrom, filter } from "rxjs/operators";

import * as actions from "gadget_v2/actions/cart";
import configure from "gadget_v2/helpers/fetcher";
import { applyPendingUpdateToCartProducts } from "gadget_v2/helpers/cart";

/* Epic triggered to request backend in order to updated cart data */
export const updateCartEpic = (action$, state$) => action$.pipe(
  ofType(actions.UPDATE_CART),
  withLatestFrom(state$),
  mergeMap(() => {
    const { organizationId, storeUUID, language } = state$.value.session;
    const session = state$.value.session[organizationId];
    const cart = state$.value.cart !== undefined && state$.value.cart[[organizationId, storeUUID]];
    const pendingUpdateProcessed = cart.dataForUpdate.pendingUpdateProcessed;

    const body = {};
    if (session.wallet) { body.wallet = session.wallet };
    if (cart && cart.formData) { body.form_data = cart.formData };
    if (cart && cart.email) { body.email = cart.email };

    const data = {
      resource: cart.uuid
        ? `/pay/organizations/${organizationId}/clientwebstores/${storeUUID}/carts/${cart.uuid}`
        : `/pay/organizations/${organizationId}/clientwebstores/${storeUUID}/carts`,
      method: cart.uuid ? 'PATCH' : 'POST',
      body: {
        ...body,
        products: Object.values(cart.dataForUpdate.products)
      }
    };

    return ajax(configure(session, language.current)(data)).pipe(
      map((res) => {
        if (res.status >= 200 && res.status < 300) {
          return actions.synchronizeCartSuccess(res.response, pendingUpdateProcessed)
        } else if (res && res.response && res.response["non_field_errors"] && res.response["non_field_errors"].some(err => err.code === "readonly_cart")) {
          return actions.emptyCart();
        } else {
          return actions.synchronizeCartError(res, pendingUpdateProcessed);
        }
      }),
      catchError((res) => {
        if (res.status === 404) {
          return of(actions.expiredCart());
        }
        return of(actions.synchronizeCartError(res, pendingUpdateProcessed));
      })
    );
  })
);

/* Epic triggered by update CTA from user about a cart */
export const beforeUpdateCartEpic = (action$, state$) => action$.pipe(
  ofType(actions.INCREMENT_PRODUCT_QUANTITY, actions.DECREMENT_PRODUCT_QUANTITY,
    actions.SUBMIT_ADDITIONAL_INFORMATION, actions.SUBMIT_WITHDRAWAL_INFORMATION,
    actions.UPDATE_PRODUCT_MENU),
  filter(() => !state$.value.cart[[state$.value.session.organizationId, state$.value.webStore.data.uuid]].loading),
  mergeMap(() => {
    const { organizationId, storeUUID } = state$.value.session;
    const cart = state$.value.cart[[organizationId, storeUUID]];
    const pendingUpdate = cart.pendingUpdate.filter(update => update.processed === false);
    const dataForUpdate = {
      products: applyPendingUpdateToCartProducts(cart.products, pendingUpdate),
      pendingUpdateProcessed: pendingUpdate.length
    }
    return of(actions.updateCart(dataForUpdate));
  })
);

export const afterUpdateCartEpic = (action$, state$) => action$.pipe(
  ofType(actions.SYNCHRONIZE_CART_SUCCESS),
  mergeMap(() => {
    const { organizationId, storeUUID } = state$.value.session;
    const cart = state$.value.cart[[organizationId, storeUUID]];
    const pendingUpdate = cart.pendingUpdate.filter(update => update.processed === false);
    if (pendingUpdate.length) {
      const dataForUpdate = {
        products: applyPendingUpdateToCartProducts(cart.products, pendingUpdate),
        pendingUpdateProcessed: pendingUpdate.length
      }
      return of(actions.updateCart(dataForUpdate));
    }
    return EMPTY;
  })
);

/* Epic triggered to intialize cart data */
// TODO: refresh cart from localstorage for no account user (but we must open GET /carts API) at initialization
export const getInitialCartEpic = (action$, state$) => action$.pipe(
  ofType(actions.INIT_CART),
  withLatestFrom(state$),
  mergeMap(([_, state]) => {
    const { isAuthenticated, organizationId, storeUUID, cartUUID, language, ...sessions } = state.session;
    const cart = state$.value.cart[[organizationId, storeUUID]]
    const session = sessions[organizationId];

    if ((!isAuthenticated && !(cart && cart.uuid)) || !cartUUID) {
      return of(actions.emptyCart());
    }

    const path = `/pay/organizations/${organizationId}/clientwebstores/${storeUUID}/carts/${cartUUID}?step=W`
    const resource = isAuthenticated ? path + `&wallet=${session.wallet}` : path
    return ajax(configure(session, language.current)({ resource })).pipe(
      map((res) => {
        if (res.status === 200 || res.status === 201) {
          return actions.synchronizeCartSuccess(res.response, 0);
        }
        return actions.synchronizeCartError(res, 0);
      }),
      catchError((res) => {
        if (res.status === 404) {
          return of(actions.expiredCart());
        }
        return of(actions.synchronizeCartError(res, 0))
      })
    );
  })
);
