export default `
back-to-cashless-account = Retour à mon compte cashless
day-monday = Lundi
day-tuesday = Mardi
day-wednesday = Mercredi
day-thursday = Jeudi
day-friday = Vendredi
day-saturday = Samedi
day-sunday = Dimanche
not-enough-money-title = Votre solde est insuffisant
validate = Valider
welcome-to-orga = Bienvenue à { $organizationId }
empty-cart-message = Votre panier est vide
view-less = Voir moins
view-more = Voir plus
view-order = Voir la commande
error-unknown = Erreur inconnue
error-500-title = Oops...
error-500-description = Une erreur est survenue, merci de réessayer plus tard
error-404-title = 404
error-404-description = Cette page n'existe pas
error-preorder-disabled = La pré-commande en ligne n'est pas disponible actuellement
error-cart-item-already-in-wallet = Vous avez atteint le nombre maximum d’achat pour ce produit.
error-cart-item-already-in-wallet-cta = Retour à l'accueil
error-cart-item-not-available = Vous ne pouvez pas ajouter ce produit dans votre panier.
error-cart-item-sold-out = Le produit que vous avez ajouté est désormais épuisé
sold_out = Produit épuisé
no-cb-title = CB non renseignée
preorder-title = Pré-commande en ligne
order-history = Historique d'achats
order-history-description = Consulter, Retirer
order-history-action = { $orderNumber ->
 [one] {$orderNumber} commande
 *[other] {$orderNumber} commandes
}
order-history-message-to-retrieve = Vous n'avez pas de commandes à retirer
order-history-message-retrieved = Vous n'avez pas de commandes retirées
personal-infos-title = Informations personnelles
personal-infos-description = Gagner du temps
personal-infos-action-no-cb = Pas de CB enregistrée
personal-infos-action-cb = CB enregistrée
preorder-description = Commander, Profiter
preorder-description-disabled = Bientôt de retour
preorder-action-disabled-no-period = La pré-commande n'est plus disponible
preorder-action-disabled-period = La pré-commande sera disponible le { DATETIME($period, day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric") }, à très vite !
preorder-action = { $productNumber ->
 [one] { $productNumber } article prêt à commander
 *[other] { $productNumber } articles prêts à commander
}
period-config-datetime-from-to = Du { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } au { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-datetime-from-until = { DATETIME($start, day: "numeric", month: "numeric", year: "numeric") }, { DATETIME($start, hour: "numeric", minute: "numeric") } - { DATETIME($end, hour: "numeric", minute: "numeric") }
period-config-datetime-from = À partir de { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-datetime-until = Jusqu'à { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
period-config-date-interval = Du DATETIME($startDate, day: "numeric", month: "numeric", year: "numeric") au DATETIME($endDate, day: "numeric", month: "numeric", year: "numeric")
your-profile = Votre profil
name = Nom
first-name = Prénom
email = Email
your-payment-method = Votre moyen de paiement
save-card = Enregistrer une carte bancaire
modify = Modifier
cart-total = Total: { $articleNumber ->
 [one] {$articleNumber} article
 *[other] {$articleNumber} articles
} { $price }
cart-total-partial = Total: { $articleNumber ->
 [one] {$articleNumber} article
 *[other] {$articleNumber} articles
}
cart = Panier
order-history-header-title = Merci pour vos commandes !
pickup-order = Récupérer ma commande
history-page-header = Historique d'achats
orders-to-pickup = Commandes à retirer ({ $quantity })
fulfilled-orders = Commandes retirées ({ $quantity })
preparing-orders = Commandes en cours de préparation ({ $quantity })
to-prepare-orders = Commandes à préparer ({ $quantity })
general-orders = Commandes ({ $quantity })
other-orders = Commandes autres ({ $quantity })
possible-withdrawal-dates = Pick-up possible :
selected-withdrawal-date = Horaire choisi :
selected-withdrawal-time-different-day = À partir de { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } jusqu'à { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-same-day = { DATETIME($start, day: "numeric", month: "numeric", year: "numeric") } entre { DATETIME($start, hour: "numeric", minute: "numeric") } - { DATETIME($end, hour: "numeric", minute: "numeric") }
selected-withdrawal-time-start = À partir de { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
selected-withdrawal-time-end = Jusqu'à { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
order-summary-ref = Commande { $orderWithdrawalRef }
order-summary-title = Résumé de l'achat
order-summary-info = Le QR code est disponible à tout moment depuis la section "Historique d'achats"
order-summary-info-anonymous = Le récapitulatif de votre commande vous a été envoyé par email
order-created-date = { DATETIME($createdDate, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
order-title = Commande { $orderWithdrawalRef }
ask-invoice = Demander un reçu
create-invoice = Création d'un reçu
download-invoice = Télécharger le reçu
invoice-hint = Merci de bien vouloir saisir les données à faire figurer sur le reçu.
invoice-hint-edit = Attention : une fois validé, le reçu ne pourra plus être modifié.
company = Société
vat-number-invoice = N° TVA Intracomm.
address = Adresse
postal-code = Code postal
city = Ville
country = Pays
next = Suivant
order = Commander
order-follow-up-cta = Suivre ma commande
order-header-title = Merci pour votre commande !
order-header-already-withdrawn = Cette commande a déjà été retirée.
order-header-cancelled = Cette commande a été annulée.
order-cancelled = Commande annulée.
order-header-initiated = Cette commande n'a pas pu aboutir.
order-initiated = Commande créée mais non aboutie.
order-header-not-fulfilled = Cette commande n'a pas pu être retirée correctement.
order-not-fulfilled = Commande non retirée.
order-header-preparing = Cette commande est en cours de préparation.
order-header-to-prepare = Cette commande est en attente de préparation.
order-to-prepare = Votre commande est en attente de préparation.
 Une fois votre commande prise en charge environ { $preparationTime ->
 [one] {$preparationTime} minute sera nécessaire
 *[other] {$preparationTime} minutes seront nécessaires
} pour la préparer.
order-preparing = Votre commande est en cours de préparation.
 Temps de préparation estimé : { $preparationTime } min.
 Heure de disponibilité estimée : { DATETIME($estimationReadyTime, hour: "numeric", minute: "numeric") }.
order-status-canceled = Annulée
order-status-delivered = Livrée
order-status-initiated = En attente
order-status-not-fulfilled = Perimée
order-status-preparing = En préparation
order-status-ready = Prête
order-status-to-deliver = À livrer
order-status-to-pay = À payer
order-status-to-prepare = À préparer
order-status-withdrawn = Retirée
order-pick-up-mode-delivery = Votre commande sera livrée à votre localisation par un de nos livreurs.
pay = Payer
payment-method-title = Moyen de paiement
create-order = Passer commande
cart-checkout-header = Moyen de paiement
cart-checkout-title = Saisir vos informations
cart-checkout-verification = Vérification du paiement
cart-checkout-error = Un problème est survenu.
cart-checkout-error-retry-with-same-card = Essayer à nouveau avec la même carte.
cart-checkout-error-retry-with-another-card = Essayer à nouveau avec une autre carte.
cart-checkout-error-invalid-card-number = Veuillez vérifier votre numéro de carte.
cart-checkout-cta = Payer { $price }
cart-validation-step-title = Informations
cart-validation-step-checkbox = J’ai pris connaissance des informations de retrait de ma commande écrites ci-dessus
cgv-step-accept = J’accepte les
cgv-step-cgv = CGV
cart-topup-title = Payer ma commande
cart-topup-info = Recharge complémentaire: vous pouvez recharger plus que le montant manquant pour régler cette commande.
cart-topup-info-sup = NB : le montant minimum de recharge cashless est de { $minAmount }.
cart-topup-cta = Valider
cart-topup-payment-title = Saisir vos informations
cart-topup-payment-info = Compléter votre solde cashless pour passer votre commande.
cart-topup-payment-cta = Valider
card-enrollment-title = Saisir vos informations
card-enrollment-cta = Enregistrer
close = Fermer
remove-card = La carte { $masked } sera remplacée par la nouvelle carte saisie.
yes = Oui
cancel = Annuler
logout = Se déconnecter
home = Accueil
home-idefix-title = Espace cashless
home-idefix-description = Solde et historique cashless
home-idefix-action = Accéder à votre compte cashless
today = Aujourd'hui
input-error-address-invalid = Veuillez entrer une adresse valide.
input-error-email-invalid = Veuillez entrer une adresse mail valide.
input-error-phone-invalid = Veuillez entrer un numéro de téléphone valide.
input-error-number-invalid = Veuillez entrer un nombre valide.
input-error-number-too-small = Le nombre doit être supérieur ou égale à { $minValue }.
input-error-number-too-big = Le nombre doit être inférieur ou égale à { $maxValue }.
input-error-date-impossible = La date choisie n'est pas possible.
input-error-date-invalid = La date n'est pas valide.
input-error-date-invalid-format = Le format de date n'est pas valide. Veuillez entrer une date au format { $dateFormat }.
input-error-date-not-configured = Aucune date ne peut être sélectionnée
input-error-date-to-early = La date doit se situer après le { $minDate }.
input-error-date-to-late = La date doit se situer avant le { $maxDate }.
input-error-input-too-short = Ce champs doit contenir au moins { $minLength } caractères.
input-error-input-too-long = Ce champs ne doit pas dépasser { $maxLength } caractères.
input-error-input-empty = Ce champs ne peut pas être vide.
input-error-input-invalid = Ce champs est invalide.
input-error-input-required = Ce champs est obligatoire.
input-error-input-not-checked = Les deux champs ne sont pas identiques.
form-submit-button = Valider
form-reset-button = Reinitialiser
ok = OK
required-informations = Informations requises
withdrawal-date = Date de retrait
withdrawal-date-select = Sélectionner une date
withdrawal-interval = - du { DATETIME($start, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") } au { DATETIME($end, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
withdrawal-location = { $count ->
 [one] Lieu de retrait
 *[other] Lieux de retrait
}
withdrawal-location-not-available = Aucun point de retrait disponible
withdrawal-location-time-possible = Lieu(x) et horaire(s) de retrait possible(s)
withdrawal-location-value = Lieu de retrait :
withdrawal-order = Retrait de commande
withdrawal-order-additional-information = Informations complémentaires
withdrawal-order-pick-up-and-delivery = Retrait et/ou livraison de commande
withdrawal-order-several-shop-information = Vous avez effectué vos achats sur { $shopCount } boutiques. Votre commande sera donc séparée en { $shopCount }.
  Merci de préciser les horaires de retrait pour chacune de ces commandes si cela est demandé.
withdrawal-products = { $productCount ->
  [one] Article
  *[other] Articles
}
withdrawal-products-pick-up = { $productCount ->
  [one] Article à récuperer
  *[other] Articles à récuperer
}
withdrawal-time = Horaire de retrait
withdrawal-time-cta-expand = Voir les horaires
withdrawal-timeslot = Créneau de retrait
withdrawal-timeslot-select = Sélectionner un créneau
withdrawal-timeslot-select-free = Horaire libre
withdrawal-time-possible = Horaire(s) de retrait possible(s)
withdrawn-date = Heure de retrait :
withdrawn-date-formatted = { DATETIME($date, day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "numeric") }
withdrawn-location = Lieu de retrait :
cart-expired-title = Votre panier vient d’expirer
cart-expired-description = Votre panier vient d’expirer : il est désormais vide. Merci d'ajouter de nouveaux produits à votre panier.
product-cta-choose-menu = Choisir
menu-cta-add-to-cart = Ajouter au panier
menu-cta-modify = Modifier
menu-cta-modify-validate = Valider les modifications
menu-error-exact-product-quantity = Vous devez choisir { $quantity ->
 [one] {$quantity} produit
 *[other] {$quantity} produits
} de cette catégorie.
menu-error-min-product-quantity = Vous devez choisir au minimum { $minQuantity ->
 [one] {$minQuantity} produit
 *[other] {$minQuantity} produits
} produits de cette catégorie.
menu-see-details-cta = Voir les détails
total = Total
cart-cta = Voir mon panier
or = ou
preorder-not-available = La pré-commande est actuellement indisponible.
`;
