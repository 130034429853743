import React from "react";

import { Box } from "@mui/material";
import PropTypes from "prop-types";

import Card from "components/Card";
import Loader from "components/Loader";

const PageGlobalLoader = ({ displayCard, margin, padding, size }) => (
  <>
    {displayCard
      ? (
        <Card margin={margin} padding={padding}>
          <Loader size={size} />
        </Card>
      )
      : (
        <Box margin={margin} padding={padding}>
          <Loader size={size} />
        </Box>
      )}
  </>
);

PageGlobalLoader.propTypes = {
  /**
   * Whether we want to display the loader inside a traditional Card component.
   */
  displayCard: PropTypes.bool,
  /**
   * The margin around the container of the loader.
   */
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The padding around the loader.
   */
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The size of the circular loader.
   */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PageGlobalLoader.defaultProps = {
  displayCard: true,
  margin: null,
  padding: null,
  size: "2rem",
};

export default PageGlobalLoader;
