import { ofType } from "redux-observable";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, map, mergeMap } from "rxjs/operators";

import * as actions from "gadget_v2/actions/wallet";
import configure from "gadget_v2/helpers/fetcher";
import { USER_FETCH_SUCCESS } from "gadget_v2/actions/user";

export const fetchWallet = (action$, state$) => action$.pipe(
  ofType(USER_FETCH_SUCCESS),
  mergeMap(() => {
    const { organizationId, language, storeUUID } = state$.value.session;
    const session = state$.value.session[organizationId];
    const wallet = session.wallet;
    const resource = `/pay/organizations/${organizationId}/clientwebstores/${storeUUID}/client/wallets/${wallet}`;
    return ajax(configure(session, language.current)({ resource })).pipe(
      map((res) => {
        if (res.status === 200) return actions.fetchWalletSuccess(res.response)
        return actions.fetchWalletError(res);
      }),
      catchError((res) => of(actions.fetchWalletError(res)))
    )
  }),
);