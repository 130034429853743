import queryString from "query-string";

import config from "config";

export const configure = (session, language) => (data) => {
  const { appKey, url: baseUrl } = config;
  const { sessionid, token } = session || {};
  const { resource, method, body, headers } = data;

  const plainResource = (resource.indexOf("?") > -1) ? resource.split("?")[0] : resource;

  const init = {};
  init.headers = { ...headers };
  init.headers["Accept-language"] = language || "en";

  if (Object.keys(init.headers).indexOf("Nemopay-Version") === -1) {
    init.headers["Nemopay-Version"] = "2020-12-01";
  }

  if (token) {
    init.headers["Authorization"] = "Bearer " + token;
  }

  if (method && method !== "GET") {
    init.method = method;
  }

  if (method === "POST" || method === "PUT" || method === "PATCH") {
    init.headers["Content-Type"] = "application/json";
    init.body = JSON.stringify(body);
  }

  let params = {};
  if (resource.indexOf("?") > -1) {
    params = queryString.parse(resource.split("?")[1])

  }
  if (sessionid) {
    params["sessionid"] = sessionid;
  }
  if (appKey) {
    params["app_key"] = appKey
  }
  const queryParameters = Object.keys(params).length > 0 ? `?${queryString.stringify(params)}` : "";

  const url = plainResource.indexOf('resources') !== -1 || plainResource.indexOf('services') !== -1 ? baseUrl + plainResource : (plainResource.indexOf("/pay") === 0 ? baseUrl + plainResource.substr(1) : baseUrl + "pay" + plainResource);

  return { ...init, url: `${url}${queryParameters}` }
};

export default configure;
