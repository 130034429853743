import React from "react";

import { useMediaQuery } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { WeezSocket } from "@weezevent/piglet";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import WeezPayAPIProvider from "api/components/WeezPayAPIProvider";
import PageGlobalLoader from "components/PageGlobalLoader";
import config from "config";
import configureStore from "configureStore";
import OrganizationIdContextProvider from "contexts/OrganizationIdContextProvider";
import { ResourceProvider } from "gadget_v2/contexts/ResourceProvider";
import ThemeProvider from "styles/ThemeProvider";
import themeDefaultConstants from "styles/themeDefaultConstants";
import { isOnIOS } from "utils/windowUtils";

import ErrorBoundary from "./components/ErrorBoundary";
import "styles/fonts/fonts";
import "styles/global.css";
import "./App.css";

const GadgetV2 = React.lazy(() => import("routers/GadgetV2").then().catch());
const ReceiptPage = React.lazy(() => import("pages/ReceiptPage"));
const ReceiptPageNew = React.lazy(() => import("pages/ReceiptPageNew"));
const PaymentReceiptPage = React.lazy(() => import("pages/PaymentReceiptPage"));
const Widget = React.lazy(() => import("routers/AppWidget").then().catch());

const queryClient = new QueryClient();

const App = React.forwardRef(
  (
    // eslint-disable-next-line no-unused-vars
    { ...props },
    ref,
  ) => {
    /* ---------- State declaration and hooks ---------- */
    const { persistor, store } = configureStore();

    const [theme, setTheme] = React.useState(null);
    const [locales, setLocales] = React.useState(null);

    /* ---------- Functions and callbacks ---------- */
    const onMessageReceivedFromIframe = React.useCallback(
      // eslint-disable-next-line no-shadow
      (event) => {
        if (!event) {
          return;
        }
        const { data } = event;
        if (data.origin === "daphne") {
          const { payload } = data;
          if (data.action === "locales") {
            setLocales(payload);
          } else if (data.action === "theme") {
            setTheme(payload);
          }
        }
      },
      [setLocales, setTheme],
    );

    const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");

    /* ---------- Side effects and imperative handle ---------- */
    React.useEffect(
      () => WeezSocket.register(onMessageReceivedFromIframe),
      [onMessageReceivedFromIframe],
    );

    React.useEffect(
      () => {
        if (isOnIOS()) {
          import("./App.ios.css").then(() => {}).catch(() => {});
        }
      },
      [],
    );

    React.useImperativeHandle(
      ref,
      () => ({
        setTitle: (title) => {
          document.title = title;
        },
      }),
      [],
    );

    /* ---------- JSX ---------- */
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <ResourceProvider {...config}>
              <ThemeProvider constants={themeDefaultConstants} mode={isDarkModeEnabled ? "dark" : "light"}>
                <Routes style={{ height: "100%" }}>
                  <Route
                    path="pay/:organizationId/widgets/:widgetUUID/*"
                    element={(
                      <React.Suspense
                        fallback={<PageGlobalLoader displayCard margin="0.5rem" />}
                      >
                        <Widget locales={locales} theme={theme} />
                      </React.Suspense>
                    )}
                  />
                  <Route
                    path="pay/:organizationId/receipt"
                    element={(
                      <React.Suspense
                        fallback={<PageGlobalLoader displayCard margin="0.5rem" />}
                      >
                        <QueryClientProvider client={queryClient}>
                          <StyledEngineProvider injectFirst>
                            <OrganizationIdContextProvider>
                              <WeezPayAPIProvider>
                                <ReceiptPage />
                              </WeezPayAPIProvider>
                            </OrganizationIdContextProvider>
                          </StyledEngineProvider>
                        </QueryClientProvider>
                      </React.Suspense>
                    )}
                  />
                  <Route
                    path="pay/:organizationId/receipt-new"
                    element={(
                      <React.Suspense
                        fallback={<PageGlobalLoader displayCard margin="0.5rem" />}
                      >
                        <ThemeProvider constants={themeDefaultConstants} mode={isDarkModeEnabled ? "dark" : "light"}>
                          <QueryClientProvider client={queryClient}>
                            <StyledEngineProvider injectFirst>
                              <OrganizationIdContextProvider>
                                <WeezPayAPIProvider>
                                  <ReceiptPageNew />
                                </WeezPayAPIProvider>
                              </OrganizationIdContextProvider>
                            </StyledEngineProvider>
                          </QueryClientProvider>
                        </ThemeProvider>
                      </React.Suspense>
                    )}
                  />
                  <Route
                    path="pay/:organizationId/payment-receipt"
                    element={(
                      <React.Suspense
                        fallback={<PageGlobalLoader displayCard margin="0.5rem" />}
                      >
                        <QueryClientProvider client={queryClient}>
                          <StyledEngineProvider injectFirst>
                            <OrganizationIdContextProvider>
                              <WeezPayAPIProvider>
                                <PaymentReceiptPage />
                              </WeezPayAPIProvider>
                            </OrganizationIdContextProvider>
                          </StyledEngineProvider>
                        </QueryClientProvider>
                      </React.Suspense>
                    )}
                  />
                  <Route
                    path="*"
                    element={(
                      <React.Suspense
                        fallback={<PageGlobalLoader displayCard={false} margin="0.5rem" padding="1rem" />}
                      >
                        <GadgetV2 />
                      </React.Suspense>
                    )}
                  />
                </Routes>
              </ThemeProvider>
            </ResourceProvider>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    );
  },
);

export default App;
